import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/configuration_keys.json';
import AdminLoader from '../../components/adminLoader';

const Index = () => {
    const navigate = useNavigate();
    const REACT_APP_API_URL = config.REACT_APP_API_URL;
    const [dataSections, setDataSections] = useState([
        {
            id: null,
            tittle: '',
            section_name: 'Main'
        },
        {
            id: null,
            section_name: 'Resource Group',
            resources: Array(5).fill(null).map((_, index) => ({
                id: null,
                title: '',
                description: '',
            }))
        }
    ]);

    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const fetchDataSections = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${REACT_APP_API_URL}/admin/investment/getInvestmentSections`);
            if (response.data && response.data.length > 0) {
                const formattedData = response.data.reduce((acc, section) => {
                    // For 'Main' section, add it to title in the first object of dataSections
                    if (section.section_name === 'Main') {
                        acc[0].id = section.id;
                        acc[0].tittle = section.title;
                    }

                    // For 'Resource Group' sections, add them to resources array
                    if (section.section_name === 'Resource Group') {
                        acc[1].id = section.id;
                        acc[1].resources.push({
                            id: section.id,
                            title: section.title,
                            description: section.description,
                        });
                    }

                    return acc;
                }, [
                    { id: null, title: '', section_name: 'Main' },
                    { id: null, section_name: 'Resource Group', resources: [] }
                ]);
                setDataSections(formattedData);
            } else {
                setDataSections([
                    { id: null, title: '', section_name: 'Main' },
                    { id: null, section_name: 'Resource Group', resources: [] }
                ]);
            }
        } catch (error) {
            console.error('Error fetching FAQ sections:', error);
        } finally {
            setIsLoading(false);
        }
    }, [REACT_APP_API_URL]);

    useEffect(() => {
        fetchDataSections();
    }, [fetchDataSections, navigate]);

    const handleInputChange = (sectionIndex, resourceIndex, field, value) => {
        setDataSections(prevSections => {
            return prevSections.map((section, index) => {
                if (index === sectionIndex) {
                    if (section.section_name === 'Resource Group') {
                        const updatedResources = section.resources.map((resource, idx) => {
                            if (idx === resourceIndex) {
                                return { ...resource, [field]: value };
                            }
                            return resource;
                        });
                        return { ...section, resources: updatedResources };
                    } else {
                        return { ...section, [field]: value };
                    }
                }
                return section;
            });
        });
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        const formData = new FormData();

        const sectionsData = dataSections.map((section, sectionIndex) => {
            if (section.section_name === 'Main') {
                return {
                    id: section.id,
                    title: section.tittle,
                    sectionName: section.section_name
                };
            } else if (section.section_name === 'Resource Group') {
                const boxGroupData = {
                    id: section.id,
                    sectionName: section.section_name,
                    resources: section.resources.map((resource, resourceIndex) => {
                        return {
                            id: resource.id,
                            title: resource.title,
                            description: resource.description,
                        };
                    })
                };
                return boxGroupData;
            }

            return null;  // Or return {}; if you'd rather return an empty object
        });

        formData.append('sections', JSON.stringify(sectionsData));

        try {
            const url = `${REACT_APP_API_URL}/admin/investment/addOrUpdateInvestmentSection2`;
            let data = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth',  // This makes the scroll smooth
            });
            setSuccessMessage(data.data);
            setTimeout(() => setSuccessMessage(''), 5000);
            fetchDataSections();
        } catch (error) {
            console.error('Error saving Product:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/login');
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div style={styles.mainContainer}>
            <div style={styles.headerWrapper}>
                <div style={styles.header}>
                    <div style={styles.breadcrumbs}>
                        <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
                        <span style={styles.breadcrumbSeparator}>/</span>
                        <Link to="/admin/Investment" style={styles.breadcrumbLink}>Investment Page</Link>
                        <span style={styles.breadcrumbSeparator}>/</span>
                        <span style={styles.currentPage}>Section 2</span>
                    </div>
                    <div style={styles.headerRight}>
                        <button style={styles.logoutButton} onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                </div>
            </div>
            <div style={styles.container}>
                <h1 style={styles.title}>Section 2 Page</h1>
                {isLoading ? (
                    <AdminLoader />
                ) : (
                    <>
                        {successMessage && <div style={styles.successMessage}>{successMessage}</div>}
                        <form onSubmit={handleSubmit} style={styles.form}>
                            {dataSections.map((section, sectionIndex) => (
                                <div key={sectionIndex} style={styles.section}>
                                    <h2 style={styles.sectionTitle}>{section.section_name} Section</h2>
                                    {section.section_name === 'Main' && (
                                        <>
                                            <label style={styles.label}>Title</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Title"
                                                value={section.tittle}
                                                onChange={(e) => handleInputChange(sectionIndex, null, 'tittle', e.target.value)}
                                                style={styles.input}
                                                required
                                            />
                                        </>
                                    )}

                                    {section.section_name === 'Resource Group' && (
                                        <>
                                            {section.resources.map((resource, resourceIndex) => (
                                                <div key={resourceIndex} className="resource-item">
                                                    <div>
                                                        <label style={styles.label}>Resource {resourceIndex + 1} Title</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Title"
                                                            value={resource.title}
                                                            onChange={(e) => handleInputChange(sectionIndex, resourceIndex, 'title', e.target.value)}
                                                            style={styles.input}
                                                            required
                                                        />
                                                        <label style={styles.label}>Resource {resourceIndex + 1} Description</label>
                                                        <textarea
                                                            placeholder="Enter Description"
                                                            value={resource.description}
                                                            onChange={(e) => handleInputChange(sectionIndex, resourceIndex, 'description', e.target.value)}
                                                            style={styles.textarea}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            ))}
                            <div style={styles.bottomActions}>
                                <button onClick={handleBack} style={styles.backButton}>
                                    ← Back
                                </button>
                                <div style={styles.rightButtons}>
                                    <button type="submit" style={styles.submitButton}>
                                        Save Sections
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

const styles = {
    editorContainer: {
        marginBottom: '20px',
        minHeight: '200px'
    },
    editor: {
        height: '200px',
        marginBottom: '50px'
    },
    mainContainer: {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
    },
    headerWrapper: {
        width: '100%',
        backgroundColor: '#fff',
        borderBottom: '1px solid #e0e0e0',
        marginBottom: '10px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '15px 20px',
    },
    breadcrumbs: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    breadcrumbLink: {
        color: '#666',
        textDecoration: 'none',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    breadcrumbSeparator: {
        color: '#999',
        fontSize: '14px',
    },
    currentPage: {
        color: '#333',
        fontWeight: '500',
        fontSize: '14px',
    },
    headerRight: {
        display: 'flex',
        gap: '10px',
    },
    image: {
        maxWidth: '100px',
        height: 'auto',
        borderRadius: '4px',
    },
    bottomActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        paddingTop: '20px',
        borderTop: '1px solid #e0e0e0',
    },
    rightButtons: {
        display: 'flex',
        gap: '10px',
    },
    backButton: {
        padding: '10px 20px',
        backgroundColor: '#f0f0f0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    submitButton: {
        backgroundColor: '#2196F3',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#1976D2',
        },
    },
    logoutButton: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    successMessage: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#d4edda',
        color: '#155724',
        border: '1px solid #c3e6cb',
        borderRadius: '4px',
        textAlign: 'center',
    },
    container: {
        backgroundColor: '#f8f9fa',
        minHeight: '100vh',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#343a40',
    },
    form: {
        padding: '6px',
        maxWidth: '600px',
        margin: '0 auto',
    },
    section: {
        backgroundColor: '#fff',
        padding: '20px',
        margin: '10px 0',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    sectionTitle: {
        textAlign: 'center',
        color: '#495057',
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
        marginTop: '10px'
    },
    input: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
    },
    textarea: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
        resize: 'vertical',
    },
};

export default Index;