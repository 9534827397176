import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';

const LoanBanner = ({ currentStep, steps, handleNext, handleBack }) => {
    let currentIndex = currentStep - 1;
    return (
        <>
            <Container fluid className="stepper-container">
                <Row className="justify-content-center row-step">
                    <Col xs={7} className="stepper-wrapper step_user">
                        {steps.map((step, index) => (
                            <div
                                key={step.id}
                                className={`step_user_box 
                                    ${currentStep === step.id ? 'active first' : ''} 
                                    ${index < currentIndex && currentIndex > 0 ? 'finish' : ''}
                                `}
                            >
                                <ul>
                                    <li>
                                        {step.icon}
                                        <span></span>
                                        <p>{step.label}</p>
                                    </li>
                                </ul>
                            </div>
                        ))}
                    </Col>
                </Row>

                {/* <Row className="justify-content-center mt-4">
                    <Col xs="auto">
                        <Button
                            variant="light"
                            onClick={handleBack}
                            disabled={currentStep === 1}
                            className="me-2"
                        >
                            Back
                        </Button>
                        <Button
                            variant="light"
                            onClick={handleNext}
                            disabled={currentStep === steps.length}
                        >
                            Next
                        </Button>
                    </Col>
                </Row> */}
            </Container>
        </>
    )
}

export default LoanBanner
