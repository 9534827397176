import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/configuration_keys.json';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AdminLoader from '../../components/adminLoader';

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image'],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
    ],
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image',
    'color', 'background'
];

const Index = () => {
    const navigate = useNavigate();
    const REACT_APP_API_URL = config.REACT_APP_API_URL;
    const [dataSections, setDataSections] = useState([
        {
            id: null,
            title: '',
            description: '',
            section_name: 'Requirements',
            point1: '',
            point2: '',
            point3: '',
            point4: '',
            image: '',
            tempImageUrl: '',
            title2: '',
            editor_content: ''
        },
    ]);

    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const fetchDataSections = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${REACT_APP_API_URL}/admin/investment/getInvestmentSections`);
            if (response.data && response.data.length > 0) {
                const requirementsSection = response.data.filter(
                    (section) => section.section_name === "Requirements"
                );
                const updatedSections = requirementsSection.map((section) => {
                    // Parse the points string into an array
                    const parsedPoints = section.points ? JSON.parse(section.points) : [];

                    // Assign each point to a separate field
                    return {
                        ...section,
                        point1: parsedPoints[0] || '',
                        point2: parsedPoints[1] || '',
                        point3: parsedPoints[2] || '',
                        point4: parsedPoints[3] || ''
                    };
                });

                setDataSections(updatedSections);
            } else {
                setDataSections([{
                    id: null,
                    title: '',
                    description: '',
                    section_name: 'Requirements',
                    point1: '',
                    point2: '',
                    point3: '',
                    point4: '',
                    image: '',
                    tempImageUrl: '',
                    title2: '',
                    editor_content: ''
                }]);
            }
        } catch (error) {
            console.error('Error fetching FAQ sections:', error);
        } finally {
            setIsLoading(false);
        }
    }, [REACT_APP_API_URL]);

    useEffect(() => {
        fetchDataSections();
    }, [fetchDataSections]);

    // Handle text input changes
    const handleInputChange = (sectionIndex, field, value) => {
        const updatedSections = [...dataSections];
        updatedSections[sectionIndex][field] = value;
        setDataSections(updatedSections);
    };

    // Handle file input changes (image)
    const handleFileChange = (sectionIndex, field, file) => {
        const updatedSections = [...dataSections];
        updatedSections[sectionIndex][field] = file;
        updatedSections[sectionIndex].tempImageUrl = URL.createObjectURL(file); // Temporary image preview
        setDataSections(updatedSections);
    };

    // Handle Quill editor change
    const handleQuillChange = (value, sectionIndex) => {
        const updatedSections = [...dataSections];
        updatedSections[sectionIndex].editor_content = value;
        setDataSections(updatedSections);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Create a FormData object to send the data
        const formData = new FormData();

        dataSections.forEach((section, index) => {
            formData.append(`sections[${index}][title]`, section.title);
            formData.append(`sections[${index}][description]`, section.description);
            formData.append(`sections[${index}][point1]`, section.point1);
            formData.append(`sections[${index}][point2]`, section.point2);
            formData.append(`sections[${index}][point3]`, section.point3);
            formData.append(`sections[${index}][point4]`, section.point4);
            formData.append(`sections[${index}][title2]`, section.title2);
            formData.append(`sections[${index}][editor_content]`, section.editor_content);
            formData.append(`sections[${index}][sectionName]`, "Requirements");
            if (section.id) {
                formData.append(`sections[${index}][id]`, section.id);
            }

            if (section.image) {
                formData.append(`sections[${index}][image]`, section.image);
            }
        });

        try {
            const url = `${REACT_APP_API_URL}/admin/investment/addOrUpdateInvestmentSection4`;
            let data = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setSuccessMessage(data.data);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',  // This makes the scroll smooth
            });
            setTimeout(() => setSuccessMessage(''), 5000);
            fetchDataSections();
        } catch (error) {
            console.error('Error saving Product:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/login');
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div style={styles.mainContainer}>
            <div style={styles.headerWrapper}>
                <div style={styles.header}>
                    <div style={styles.breadcrumbs}>
                        <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
                        <span style={styles.breadcrumbSeparator}>/</span>
                        <Link to="/admin/Investment" style={styles.breadcrumbLink}>Investment Page</Link>
                        <span style={styles.breadcrumbSeparator}>/</span>
                        <span style={styles.currentPage}>Section 4</span>
                    </div>
                    <div style={styles.headerRight}>
                        <button style={styles.logoutButton} onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                </div>
            </div>
            <div style={styles.container}>
                <h1 style={styles.title}>Section 4 Page</h1>
                {isLoading ? (
                    <AdminLoader />
                ) : (
                    <>
                        {successMessage && <div style={styles.successMessage}>{successMessage}</div>}
                        <form onSubmit={handleSubmit} style={styles.form}>
                            {dataSections.map((section, sectionIndex) => (
                                <div key={sectionIndex} style={styles.section}>
                                    <h2 style={styles.sectionTitle}>{section.section_name} Section</h2>
                                    <>
                                        {section.tempImageUrl ? (
                                            <img
                                                src={section.tempImageUrl}
                                                alt={`Banner ${sectionIndex + 1}`}
                                                style={styles.image}
                                            />
                                        ) : section.image && (
                                            <img
                                                src={`${REACT_APP_API_URL}/uploads/${section.image}`}
                                                alt={`Banner ${sectionIndex + 1}`}
                                                style={styles.image}
                                            />
                                        )}
                                        <label style={styles.label}>Main Banner Image</label>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => handleFileChange(sectionIndex, 'image', e.target.files[0])}
                                            style={styles.input}
                                            required={!section.image}
                                        />
                                        <label style={styles.label}>Title</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Title"
                                            value={section.title}
                                            onChange={(e) => handleInputChange(sectionIndex, 'title', e.target.value)}
                                            style={styles.input}
                                            required
                                        />
                                        <label style={styles.label}>Description</label>
                                        <textarea
                                            placeholder="Enter Description"
                                            value={section.description}
                                            onChange={(e) => handleInputChange(sectionIndex, 'description', e.target.value)}
                                            style={styles.textarea}
                                            required
                                        />

                                        <label style={styles.label}>Point1</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Title"
                                            value={section.point1}
                                            onChange={(e) => handleInputChange(sectionIndex, 'point1', e.target.value)}
                                            style={styles.input}
                                            required
                                        />
                                        <label style={styles.label}>Point2</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Title"
                                            value={section.point2}
                                            onChange={(e) => handleInputChange(sectionIndex, 'point2', e.target.value)}
                                            style={styles.input}
                                            required
                                        />
                                        <label style={styles.label}>Point3</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Title"
                                            value={section.point3}
                                            onChange={(e) => handleInputChange(sectionIndex, 'point3', e.target.value)}
                                            style={styles.input}
                                            required
                                        />

                                        <label style={styles.label}>Point4</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Title"
                                            value={section.point4}
                                            onChange={(e) => handleInputChange(sectionIndex, 'point4', e.target.value)}
                                            style={styles.input}
                                            required
                                        />

                                        <label style={styles.label}>Title 2</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Title"
                                            value={section.title2}
                                            onChange={(e) => handleInputChange(sectionIndex, 'title2', e.target.value)}
                                            style={styles.input}
                                            required
                                        />

                                        <label style={styles.label}>Detailed Resource Information</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={section.editor_content}
                                            onChange={(value) => handleQuillChange(value, sectionIndex)}
                                            modules={modules}
                                            formats={formats}
                                        />
                                    </>
                                </div>
                            ))}
                            <div style={styles.bottomActions}>
                                <button onClick={handleBack} style={styles.backButton}>
                                    ← Back
                                </button>
                                <div style={styles.rightButtons}>
                                    <button type="submit" style={styles.submitButton}>
                                        Save Section
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

const styles = {
    editorContainer: {
        marginBottom: '20px',
        minHeight: '200px'
    },
    editor: {
        height: '200px',
        marginBottom: '50px' // Add extra margin to account for Quill toolbar
    },
    mainContainer: {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
    },
    headerWrapper: {
        width: '100%',
        backgroundColor: '#fff',
        borderBottom: '1px solid #e0e0e0',
        marginBottom: '10px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '15px 20px',
    },
    breadcrumbs: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    breadcrumbLink: {
        color: '#666',
        textDecoration: 'none',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    breadcrumbSeparator: {
        color: '#999',
        fontSize: '14px',
    },
    currentPage: {
        color: '#333',
        fontWeight: '500',
        fontSize: '14px',
    },
    headerRight: {
        display: 'flex',
        gap: '10px',
    },
    image: {
        maxWidth: '100px',
        height: 'auto',
        borderRadius: '4px',
    },
    bottomActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        paddingTop: '20px',
        borderTop: '1px solid #e0e0e0',
    },
    rightButtons: {
        display: 'flex',
        gap: '10px',
    },
    backButton: {
        padding: '10px 20px',
        backgroundColor: '#f0f0f0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    submitButton: {
        backgroundColor: '#2196F3',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#1976D2',
        },
    },
    logoutButton: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    successMessage: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#d4edda',
        color: '#155724',
        border: '1px solid #c3e6cb',
        borderRadius: '4px',
        textAlign: 'center',
    },
    container: {
        // padding: '20px',
        backgroundColor: '#f8f9fa',
        minHeight: '100vh',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#343a40',
    },
    form: {
        padding: '6px',
        maxWidth: '600px',
        margin: '0 auto',
    },
    section: {
        backgroundColor: '#fff',
        padding: '20px',
        margin: '10px 0',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    sectionTitle: {
        textAlign: 'center',
        color: '#495057',
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
        marginTop: '10px'
    },
    input: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
    },
    textarea: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
        resize: 'vertical',
    },
};

export default Index;