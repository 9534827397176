import React from 'react';
import { Oval } from 'react-loader-spinner'; // Import the Oval loader

const Loader = () => {
    return (
        <div className="loader-container d-flex justify-content-center" >
            <Oval
                height={50}        // Loader height
                width={50}         // Loader width
                color="#243d59"    // Customize loader color
                ariaLabel="oval-loading"  // Accessibility label
                visible={true}     // Make it visible
            />
        </div>
    );
};

export default Loader;