import React, { useEffect, useCallback, useState } from 'react'
import RowComponentProps from '../My-comonent/OurProductComponent/RowComponentProps'
import OneShortTearm from '../My-comonent/OneShortTearm'
import PageBanner from '../My-comonent/OurProductComponent/PageBanner'
import axios from 'axios';
import config from '../config/configuration_keys.json';
import Loader from '../components/loader';

const OurProduct = () => {
    const REACT_APP_API_URL = config.REACT_APP_API_URL;
    const [chooseUsSections, setChooseUsSections] = useState([
        { id: null, image: '', tittle: '', description: '' },
        { id: null, image: '', tittle: '', description: '' },
        { id: null, image: '', tittle: '', description: '' },
        { id: null, image: '', tittle: '', description: '' },
        { id: null, image: '', tittle: '', description: '' },
        { id: null, image: '', tittle: '', description: '' },
        { id: null, image: '', tittle: '', description: '' },
    ]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchChooseUsSections = useCallback(async () => {
        try {
            const response = await axios.get(`${REACT_APP_API_URL}/admin/getProductSections`);
            if (response.status === 200) {
                setChooseUsSections(response.data);
                setIsLoading(false);
            } else {
                setChooseUsSections([
                    { id: null, image: '', tittle: '', description: '' },
                    { id: null, image: '', tittle: '', description: '' },
                    { id: null, image: '', tittle: '', description: '' },
                    { id: null, image: '', tittle: '', description: '' },
                    { id: null, image: '', tittle: '', description: '' },
                    { id: null, image: '', tittle: '', description: '' },
                    { id: null, image: '', tittle: '', description: '' },
                ]);
            }
        } catch (error) {
            console.error('Error fetching Choose Us sections:', error);
        } finally {
            setIsLoading(false);
        }
    }, [REACT_APP_API_URL]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchChooseUsSections();
    }, [fetchChooseUsSections]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <PageBanner chooseUsSections={chooseUsSections} />
                    <div className='our_row-wraper'>
                        <RowComponentProps chooseUsSections={chooseUsSections} />
                    </div>
                </>
            )}
            <OneShortTearm />
        </>
    )
}

export default OurProduct