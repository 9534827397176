import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import apiClient from '../axios/request';
import AdminLoader from '../components/adminLoader';

const HomeTestimonials = () => {
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState([{ id: null, title: '', designation: '', review: '' }]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Authentication check
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('user');
    if (!isAuthenticated) {
      navigate('/login'); // Redirect to login if not logged in
    }
  }, [navigate]);

  // Fetch existing testimonials from backend
  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      setIsLoading(true);
      const response = await apiClient.get('/api/testimonials');
      setTestimonials(response.data.map(testimonial => ({ ...testimonial })));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
    }
  };

  // Handle testimonial input changes
  const handleTestimonialChange = (index, field, value) => {
    const updatedTestimonials = testimonials.map((testimonial, i) => {
      if (i === index) {
        return { ...testimonial, [field]: value };
      }
      return testimonial;
    });
    setTestimonials(updatedTestimonials);
  };

  // Add a new testimonial
  const addTestimonial = () => {
    setTestimonials([...testimonials, { id: null, title: '', designation: '', review: '' }]);
  };

  // Remove a testimonial from UI and backend
  const removeTestimonial = async (id, index) => {
    if (id) {
      try {
        await apiClient.delete(`/api/testimonials/${id}`);
      } catch (error) {
        console.error('Error deleting testimonial:', error);
      }
    }
    setTestimonials(testimonials.filter((_, i) => i !== index));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await apiClient.post('/api/save-testimonials', testimonials);
      fetchTestimonials();
      // Show success message
      setSuccessMessage('Testimonials saved successfully!');
      setIsLoading(false);

      // Clear the success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);

    } catch (error) {
      console.error('Error saving testimonials:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.headerWrapper}>
        <div style={styles.header}>
          <div style={styles.breadcrumbs}>

            <div style={styles.breadcrumbs}>
              <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
              <span style={styles.breadcrumbSeparator}>/</span>
              <Link to="/admin/home" style={styles.breadcrumbLink}>Home Page</Link>
              <span style={styles.breadcrumbSeparator}>/</span>
              <span style={styles.currentPage}>Testimonials Section</span>
            </div>
          </div>
          <div style={styles.headerRight}>
            <button style={styles.logoutButton} onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div style={styles.title}>
        <h1>Testimonials Section</h1>
      </div>
      <div style={styles.container}>
        {isLoading ? (
          <AdminLoader />
        ) : (
          <>
            <div style={styles.card}>
              <form onSubmit={handleSubmit}>
                {testimonials.map((testimonial, index) => (
                  <div key={index} style={styles.testimonialItem}>
                    <label style={styles.label}>Testimonial {index + 1} Title</label>
                    <input
                      type="text"
                      placeholder="Enter title"
                      value={testimonial.title}
                      onChange={(e) => handleTestimonialChange(index, 'title', e.target.value)}
                      style={styles.input}
                      required
                    />
                    <label style={styles.label}>Designation</label>
                    <input
                      type="text"
                      placeholder="Enter designation"
                      value={testimonial.designation}
                      onChange={(e) => handleTestimonialChange(index, 'designation', e.target.value)}
                      style={styles.input}
                      required
                    />
                    <label style={styles.label}>Review</label>
                    <textarea
                      placeholder="Enter review"
                      value={testimonial.review}
                      onChange={(e) => handleTestimonialChange(index, 'review', e.target.value)}
                      style={styles.textarea}
                      required
                    />
                    {testimonials.length > 1 && (
                      <button type="button" onClick={() => removeTestimonial(testimonial.id, index)} style={styles.removeButton}>
                        Remove
                      </button>
                    )}
                  </div>
                ))}

                <div style={styles.bottomActions}>
                  <button onClick={handleBack} style={styles.backButton}>
                    ← Back
                  </button>
                  <div style={styles.rightButtons}>
                    <button type="button" onClick={addTestimonial} style={styles.addButton}>
                      Add
                    </button>
                    <button type="submit" style={styles.submitButton}>
                      Save
                    </button>
                  </div>
                </div>
                {successMessage && (
                  <div style={styles.successMessage}>{successMessage}</div>
                )}
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

// Inline styles
const styles = {
  title: {
    textAlign: 'center',
    // marginBottom: '10px',
    color: '#343a40',
  },
  mainContainer: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
  headerWrapper: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '15px 20px',
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  breadcrumbLink: {
    color: '#666',
    textDecoration: 'none',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbSeparator: {
    color: '#999',
    fontSize: '14px',
  },
  currentPage: {
    color: '#333',
    fontWeight: '500',
    fontSize: '14px',
  },
  headerRight: {
    display: 'flex',
    gap: '10px',
  },
  container: {
    padding: '20px',
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
  },
  bottomActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    paddingTop: '20px',
    borderTop: '1px solid #e0e0e0',
  },
  card: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: '0 auto',
  },
  cardTitle: {
    fontSize: '1.5rem',
    color: '#007bff',
    marginBottom: '20px',
  },
  testimonialItem: {
    marginBottom: '20px',
  },
  label: {
    fontSize: '1rem',
    color: '#555',
    marginBottom: '5px',
    display: 'block',
  },
  input: {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  textarea: {
    display: 'block',
    width: '100%',
    padding: '8px',
    height: '80px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  addButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
  removeButton: {
    backgroundColor: '#dc3545',
    color: '#fff',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  submitButton: {
    backgroundColor: '#2196F3',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1976D2',
    },
  },
  successMessage: {
    color: 'green',
    fontWeight: 'bold',
    marginTop: '15px',
    textAlign: 'center',
  },
  logoutButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  backButton: {
    padding: '10px 20px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  rightButtons: {
    display: 'flex',
    gap: '10px',
  },
};

export default HomeTestimonials;
