import React from 'react'
import config from '../../config/configuration_keys.json';

function Requirement({ dataSections }) {
  const REACT_APP_API_URL = config.REACT_APP_API_URL;

  return (
    <div className='requirement-sec'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='left-content'>
              <h2>{dataSections && dataSections[0]?.title}</h2>
              <p>{dataSections && dataSections[0]?.description}</p>
              <ul>
                <li>{dataSections && dataSections[0]?.point1}</li>
                <li>{dataSections && dataSections[0]?.point2}</li>
                <li>{dataSections && dataSections[0]?.point3}</li>
                <li>{dataSections && dataSections[0]?.point4}</li>
              </ul>
              <h3>{dataSections && dataSections[0]?.title2}</h3>
              <p dangerouslySetInnerHTML={{ __html: dataSections && dataSections[0]?.editor_content }} />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='right-content'>
              <img
                src={`${REACT_APP_API_URL}/uploads/${dataSections && dataSections[0]?.image}`}
                alt='Loading...'
                className='image-req'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Requirement
