import React, { useState, useCallback, useEffect } from 'react'
import Banner from '../My-comonent/HomePageComponent/Banner'
import BoxesHere from '../My-comonent/HomePageComponent/BoxesHere'
import WhyChooseUs from '../My-comonent/WhyChooseUs'
import OneShortTearm from '../My-comonent/OneShortTearm'
import HomeChooseRight from '../My-comonent/HomePageComponent/HomeChooseRight'
import HomeHaveTrusted from '../My-comonent/HomePageComponent/HomeHaveTrusted'
import Loader from '../components/loader'
import config from '../config/configuration_keys.json';

const Home = () => {
  const REACT_APP_API_URL = config.REACT_APP_API_URL;
  // Loading states for each section
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const [isHomePageLoading, setIsHomePageLoading] = useState(true);
  const [isBoxSectionLoading, setIsBoxSectionLoading] = useState(true);
  const [isWhyChooseUsLoading, setIsWhyChooseUsLoading] = useState(true);
  const [isTrustedSectionLoading, setIsTrustedSectionLoading] = useState(true);

  // Data states for each section
  const [bannerData, setBannerData] = useState([]);
  const [homePageData, setHomePageData] = useState([]);
  const [boxData, setBoxData] = useState([]);
  const [trustedData, setTrustedData] = useState([]);
  const [whyChooseUsData, setWhyChooseUsData] = useState([]);

  const fetchBanners = useCallback(async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/api/banners`);
      if (!response.ok) {
        throw new Error('Failed to fetch banners');
      }
      const data = await response.json();
      setBannerData(data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsBannerLoading(false);
    }
  }, [REACT_APP_API_URL]);

  const fetchHomePageData = useCallback(async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/api/options`);
      if (!response.ok) {
        throw new Error('Failed to fetch homepage data');
      }
      const data = await response.json();
      setHomePageData(data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsHomePageLoading(false);
    }
  }, [REACT_APP_API_URL]);

  const fetchServiceBoxes = useCallback(async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/api/services`);
      if (!response.ok) {
        throw new Error('Failed to fetch service boxes');
      }
      const data = await response.json();
      setBoxData(data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsBoxSectionLoading(false);
    }

  }, [REACT_APP_API_URL]);

  const fetchTrustedSection = useCallback(async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/api/testimonials`);
      if (!response.ok) {
        throw new Error('Failed to fetch trusted section data');
      }
      const data = await response.json();
      setTrustedData(data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsTrustedSectionLoading(false);
    }
  }, [REACT_APP_API_URL]);

  const fetchWhyChooseUsData = useCallback(async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/api/choose-us`);
      if (!response.ok) {
        throw new Error('Failed to fetch "Why Choose Us" section data');
      }
      const data = await response.json();
      setWhyChooseUsData(data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsWhyChooseUsLoading(false);
    }
  }, [REACT_APP_API_URL]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      await fetchBanners();
      await fetchHomePageData();
      await fetchServiceBoxes();
      await fetchTrustedSection();
      await fetchWhyChooseUsData();
    };

    fetchData();
  }, [fetchBanners, fetchHomePageData, fetchServiceBoxes, fetchTrustedSection, fetchWhyChooseUsData]);

  const isAllDataLoaded = !isBannerLoading && !isHomePageLoading && !isBoxSectionLoading && !isWhyChooseUsLoading && !isTrustedSectionLoading;

  return (
    <div heading="hello">
      {!isAllDataLoaded ? (
        <Loader />
      ) : (
        <>
          <Banner banners={bannerData} />
          <HomeChooseRight homeBanners={homePageData} />
          <BoxesHere boxBanners={boxData} />
          <WhyChooseUs whyBanners={whyChooseUsData} />
          <HomeHaveTrusted homeHaveBanners={trustedData} />
        </>
      )}
      <OneShortTearm />
    </div >
  )
}

export default Home
