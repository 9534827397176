import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  // Check if user is logged in
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('user');
    if (!isAuthenticated) {
      navigate('/login'); // Redirect to login if not logged in
    }
  }, [navigate]);

  // Handle page navigation
  const handleLogout = () => {
    localStorage.removeItem('user'); // Remove authentication token
    navigate('/login'); // Redirect to login page
  };
  const handlePageClick = (section) => {
    const sectionFile = `admin/investment-${section.replace(' ', '')}`; // Create the file name dynamically
    navigate(`/${sectionFile.toLowerCase()}`); // Navigate to the respective section file
  };

  // Sections list
  const sections = [
    'Section 1',
    'Section 2',
    'Section 3',
    'Section 4'
  ];

  return (
    <div style={styles.mainContainer}>
      <div style={styles.headerWrapper}>
        <div style={styles.header}>
          <div style={styles.breadcrumbs}>
            <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
            <span style={styles.breadcrumbSeparator}>/</span>
            <span style={styles.currentPage}>Investment Page</span>
          </div>
          <div style={styles.headerRight}>
            <button style={styles.logoutButton} onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div style={styles.container}>
        <div style={styles.title}>
          <h1>Investment Page</h1>
        </div>
        <div style={styles.pageList}>
          <ul>
            {sections.map((section, index) => (
              <li
                key={index}
                onClick={() => handlePageClick(section)}
                style={styles.listItem}
              >
                {section}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

// Inline styles for the component
const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f8f9fa',
    height: '100vh',
  },
  mainContainer: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
  headerWrapper: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '15px 20px',
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  breadcrumbLink: {
    color: '#666',
    textDecoration: 'none',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbSeparator: {
    color: '#999',
    fontSize: '14px',
  },
  currentPage: {
    color: '#333',
    fontWeight: '500',
    fontSize: '14px',
  },
  headerRight: {
    display: 'flex',
    gap: '10px',
  },
  pageList: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    width: '60%',
    margin: '0 auto'
  },
  listItem: {
    cursor: 'pointer',
    margin: '10px 0',
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: '#f1f1f1',
    textAlign: 'center',
    fontWeight: 'bold',
    transition: 'background-color 0.3s',
  },
  logoutButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#343a40',
  },
};

export default Home;
