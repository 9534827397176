import React from 'react';
import { Modal, Button, CloseButton } from 'react-bootstrap';

const MessageModal = ({
    show,
    handleClose,
    message,
    title,
    closeButtonText
}) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header className="custom-modal-header">
                <Modal.Title>{title}</Modal.Title>
                <CloseButton
                    onClick={handleClose}
                    className="custom-close-button"
                />
            </Modal.Header>
            <Modal.Body className="modal-body-centered">
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="custom-close-btn"
                >
                    {closeButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MessageModal;