import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/configuration_keys.json';
import AdminLoader from '../../components/adminLoader';

const ProductPage = () => {
    const navigate = useNavigate();
    const REACT_APP_API_URL = config.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(true);

    const [chooseUsSections, setChooseUsSections] = useState([
        { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
        { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
        { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
        { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
        { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
        { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
        { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
    ]);

    const [successMessage, setSuccessMessage] = useState('');

    const fetchChooseUsSections = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${REACT_APP_API_URL}/admin/getProductSections`);
            if (response.data.length > 0) {
                setChooseUsSections(response.data);
                setIsLoading(false);
            } else {
                setChooseUsSections([
                    { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
                    { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
                    { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
                    { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
                    { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
                    { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
                    { id: null, image: '', tittle: '', description: '', tempImageUrl: '' },
                ]);
            }
        } catch (error) {
            console.error('Error fetching Choose Us sections:', error);
        }
    }, [REACT_APP_API_URL]);

    useEffect(() => {
        fetchChooseUsSections();
    }, [fetchChooseUsSections]);


    const handleInputChange = (index, field, value) => {
        const updatedSections = chooseUsSections.map((section, i) => {
            if (i === index) {
                return {
                    ...section,
                    [field]: value
                };
            }
            return section;
        });
        setChooseUsSections(updatedSections);
    };

    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            // Create a temporary URL for preview
            const tempUrl = URL.createObjectURL(file);
            const updatedSections = chooseUsSections.map((section, i) => {
                if (i === index) {
                    return {
                        ...section,
                        image: file,
                        tempImageUrl: tempUrl
                    };
                }
                return section;
            });
            setChooseUsSections(updatedSections);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();

        const sectionsData = chooseUsSections.map(section => ({
            id: section.id,
            tittle: section.tittle,
            description: section.description,
            image: typeof section.image === 'string' ? section.image : null // Keep existing image path if no new file
        }));

        formData.append('sections', JSON.stringify(sectionsData));

        // Append only the new files
        chooseUsSections.forEach((section, index) => {
            if (section.image instanceof File) {
                formData.append(`sections[${index}][image]`, section.image);
            }
        });

        try {
            const url = `${REACT_APP_API_URL}/admin/addOrUpdateProduct`;
            let data = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            fetchChooseUsSections();
            setIsLoading(false);
            setSuccessMessage(data.data);
            setTimeout(() => setSuccessMessage(''), 5000);
        } catch (error) {
            console.error('Error saving Product:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/login');
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div style={styles.mainContainer}>
            <div style={styles.headerWrapper}>
                <div style={styles.header}>
                    <div style={styles.breadcrumbs}>
                        <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
                        <span style={styles.breadcrumbSeparator}>/</span>
                        <span style={styles.currentPage}>Product Page</span>
                    </div>
                    <div style={styles.headerRight}>
                        <button style={styles.logoutButton} onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                </div>
            </div>

            <div style={styles.container}>
                {isLoading ? (
                    <AdminLoader />
                ) : (
                    <>
                        <h1 style={styles.title}>Product Page</h1>
                        <form onSubmit={handleSubmit} style={styles.form}>
                            {chooseUsSections.map((section, index) => (
                                <div key={index} style={styles.section}>
                                    <h2 style={styles.sectionTitle}>Section {index + 1}</h2>
                                    {section.tempImageUrl ? (
                                        <img
                                            src={section.tempImageUrl}
                                            alt={`Banner ${index + 1}`}
                                            style={styles.image}
                                        />
                                    ) : section.image && (
                                        <img
                                            src={`${REACT_APP_API_URL}/uploads/${section.image}`}
                                            alt={`Banner ${index + 1}`}
                                            style={styles.image}
                                        />
                                    )}

                                    <label style={styles.label}>Banner {index + 1} Image</label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(index, e)}
                                        style={styles.input}
                                        required={!section.image}
                                    />
                                    <label style={styles.label}>Title</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Title"
                                        value={section.tittle}
                                        onChange={(e) => handleInputChange(index, 'tittle', e.target.value)}
                                        style={styles.input}
                                        required
                                    />
                                    <label style={styles.label}>Description</label>
                                    <textarea
                                        placeholder="Enter Description"
                                        value={section.description}
                                        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                        style={styles.textarea}
                                        required
                                    />
                                </div>
                            ))}
                            {successMessage && <div style={styles.successMessage}>{successMessage}</div>}
                            <div style={styles.bottomActions}>
                                <button onClick={handleBack} style={styles.backButton}>
                                    ← Back
                                </button>
                                <div style={styles.rightButtons}>
                                    <button type="submit" style={styles.submitButton}>
                                        Save Product Sections
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

const styles = {
    mainContainer: {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
    },
    headerWrapper: {
        width: '100%',
        backgroundColor: '#fff',
        borderBottom: '1px solid #e0e0e0',
        marginBottom: '10px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '15px 20px',
    },
    breadcrumbs: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    breadcrumbLink: {
        color: '#666',
        textDecoration: 'none',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    breadcrumbSeparator: {
        color: '#999',
        fontSize: '14px',
    },
    currentPage: {
        color: '#333',
        fontWeight: '500',
        fontSize: '14px',
    },
    headerRight: {
        display: 'flex',
        gap: '10px',
    },
    contentContainer: {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '0 20px',
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    bannerItem: {
        marginBottom: '30px',
        padding: '20px',
        borderRadius: '4px',
        backgroundColor: 'rgb(235 233 233)',
    },
    inputGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        color: '#666',
        fontWeight: 'bold',
    },
    input: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        fontSize: '16px',
    },
    imagePreview: {
        marginBottom: '15px',
    },
    image: {
        maxWidth: '100px',
        height: 'auto',
        borderRadius: '4px',
    },
    bottomActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        paddingTop: '20px',
        borderTop: '1px solid #e0e0e0',
    },
    rightButtons: {
        display: 'flex',
        gap: '10px',
    },
    backButton: {
        padding: '10px 20px',
        backgroundColor: '#f0f0f0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    addButton: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#45a049',
        },
    },
    submitButton: {
        backgroundColor: '#2196f3',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'block',
        width: '100%',
    },
    removeButton: {
        backgroundColor: '#f44336',
        color: 'white',
        padding: '8px 15px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px',
        '&:hover': {
            backgroundColor: '#d32f2f',
        },
    },
    logoutButton: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    successMessage: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#d4edda',
        color: '#155724',
        border: '1px solid #c3e6cb',
        borderRadius: '4px',
        textAlign: 'center',
    },
    errorMessage: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#f2dede',
        color: '#a94442',
        borderRadius: '4px',
        textAlign: 'center',
    },

    container: {
        // padding: '20px',
        backgroundColor: '#f8f9fa',
        minHeight: '100vh',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#343a40',
    },

    form: {
        padding: '6px',
        maxWidth: '600px',
        margin: '0 auto',
    },
    section: {
        backgroundColor: '#fff',
        padding: '20px',
        margin: '10px 0',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    sectionTitle: {
        textAlign: 'center',
        color: '#495057',
        marginBottom: '20px',
    },
    textarea: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        // border: '1px solid #ccc',
        fontSize: '16px',
        resize: 'vertical',
    },
};

export default ProductPage;