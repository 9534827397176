import React from 'react';

const EmploymentIcon = (props) => (
    <svg
        width={44}
        height={44}
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                d="M21.7295 19.8367C26.3785 19.8367 30.1477 16.068 30.1477 11.4194C30.1477 6.76998 26.3785 3 21.7295 3C17.0801 3 13.3105 6.76998 13.3105 11.4194C13.3101 16.0684 17.0797 19.8367 21.7295 19.8367ZM21.7295 5.64185C24.9151 5.64185 27.5062 8.23387 27.5062 11.4194C27.5062 14.6045 24.9151 17.1953 21.7295 17.1953C18.5436 17.1953 15.952 14.6045 15.952 11.4194C15.952 8.23387 18.5436 5.64185 21.7295 5.64185Z"
                fill="white"
            />
            <path
                d="M36.4597 31.05C36.4597 23.2033 27.6898 22.8667 27.6898 22.8667H15.7708C15.7708 22.8667 7 23.1561 7 31.05C7 37.8673 7 38.463 7 38.463H17.9547L17.8109 39.096L21.6632 41.857L25.57 38.9262L25.4474 38.463H36.4597C36.4597 38.463 36.4597 37.8691 36.4597 31.05ZM10.0326 35.8636V31.05C10.0326 28.8713 10.783 27.5369 12.5909 26.6388C14.1349 25.8729 15.6954 25.8993 15.795 25.8993H19.5932L20.6542 27.0877L18.5738 35.8636H10.0326ZM33.4271 35.8636H24.7291L22.3355 27.16L23.5334 25.8993H27.6556C27.7925 25.8993 29.2551 25.8894 30.7463 26.5973C32.6378 27.4962 33.4271 28.8431 33.4271 31.05V35.8636Z"
                fill="white"
            />
        </g>
    </svg>

);

export default EmploymentIcon;