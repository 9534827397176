import React, { useEffect } from 'react'
import Header from '../My-comonent/Header'
import Footer from '../My-comonent/Footer'
import { Outlet } from 'react-router'

import AOS from "aos";
import "aos/dist/aos.css";
function AuthLayout() {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <div>
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    </div>
  )
}

export default AuthLayout
