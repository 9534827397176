import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const FinanceAccordion = ({ dataSections }) => {
    const [openSection, setOpenSection] = useState(null);

    let resources = dataSections[1]?.resources.sort((a, b) => a.position - b.position);

    const toggleSection = (index, event) => {
        // Prevent event from propagating
        event.stopPropagation();

        // Toggle the section
        setOpenSection(openSection === index ? null : index);
    };

    return (
        <div className="accordion-container">
            {resources.map((section, index) => (
                <div key={index} className="accordion-item">
                    <button
                        className={`accordion-header ${openSection === index ? 'active' : ''}`}
                        onClick={(e) => toggleSection(index, e)}
                    >
                        <span>{section.title}</span>
                        <span className="icon"
                            onClick={(e) => toggleSection(index, e)}
                        >{openSection === index ? <AiOutlineMinus /> : <AiOutlinePlus />}</span>
                    </button>
                    <div className={`accordion-content ${openSection === index ? 'open' : ''}`}
                        style={{
                            height: openSection === index ? 'auto' : '0',
                            opacity: openSection === index ? '1' : '0',
                            visibility: openSection === index ? 'visible' : 'hidden'
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: section.ckeditor_content }} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FinanceAccordion;