import React, { useEffect } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const PersonalContact = ({
    inputData,
    setInputData,
    handleFileChange,
    handleSubmit,
    submitted,
    errorMessage,
    setErrorMessage,
    refs
}) => {
    const allCities = [
        { id: 1, name: 'Mumbai' },
        { id: 2, name: 'Delhi' },
        { id: 3, name: 'Bangalore' },
    ];

    const allStates = [
        { isoCode: 'AP', name: 'Andhra Pradesh' },
        { isoCode: 'UP', name: 'Uttar Pradesh' },
        { isoCode: 'MH', name: 'Maharashtra' },
    ];

    const handleInput = (event) => {
        setInputData({ ...inputData, [event.target.name]: event.target.value });
        setErrorMessage((prevState) => ({
            ...prevState,
            [event.target.name]: "",
        }));
    };

    useEffect(() => {
        const storedData = localStorage.getItem('formData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setInputData(parsedData);
        }
    }, [setInputData]);

    return (
        <>
            <div className='loan_contact'>
                <Container>
                    <div className='details'>Personal Details</div>
                    <Form>
                        <Row>
                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Surname
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.surname}
                                            type='text'
                                            placeholder='Surname'
                                            name='surname'
                                            value={inputData.surname || ''}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.surname && <p className="error">{errorMessage.surname}</p>}
                                    </label>
                                </div>
                                <div className='input_box sum-box-choose'>
                                    <label>
                                        <div className='loan_title'>
                                            6 month's Salary Bank Account Statement
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.salaryStatement}
                                            type="file"
                                            accept="image/*"
                                            name="salaryStatement"
                                            onChange={handleFileChange}
                                            required
                                        />
                                        {inputData?.salaryStatement?.name ?? (
                                            <div className='loan_title'>
                                                {inputData?.salaryStatement?.name}
                                            </div>
                                        )}
                                        {errorMessage.salaryStatement && <p className="error">{errorMessage.salaryStatement}</p>}
                                    </label>
                                </div>
                            </Col>

                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            First Name
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.first_name}
                                            type='text'
                                            placeholder='First name'
                                            name='first_name'
                                            value={inputData.first_name || ''}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.first_name && <p className="error">{errorMessage.first_name}</p>}
                                    </label>
                                </div>

                                <div className='input_box sum-box-choose'>
                                    <label>
                                        <div className='loan_title'>
                                            Passport Photograph
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.passportPhoto}
                                            type="file"
                                            accept="image/*"
                                            name="passportPhoto"
                                            onChange={handleFileChange}
                                            required
                                        />
                                        {inputData?.passportPhoto?.name ?? (
                                            <div className='loan_title'>
                                                {inputData?.passportPhoto?.name}
                                            </div>
                                        )}
                                        {errorMessage.passportPhoto && <p className="error">{errorMessage.passportPhoto}</p>}
                                    </label>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Middle Name
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.middle_name}
                                            type='text'
                                            placeholder='Middle name'
                                            name='middle_name'
                                            value={inputData.middle_name}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.middle_name && <p className="error">{errorMessage.middle_name}</p>}
                                    </label>
                                </div>
                                <div className='input_box sum-box-choose'>
                                    <label>
                                        <div className='loan_title'>
                                            Government Issued ID
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.governmentId}
                                            type="file"
                                            accept="image/*"
                                            name="governmentId"
                                            onChange={handleFileChange}
                                            required
                                        />
                                        {inputData?.governmentId?.name ?? (
                                            <div className='loan_title'>
                                                {inputData?.governmentId?.name}
                                            </div>
                                        )}
                                        {errorMessage.governmentId && <p className="error">{errorMessage.governmentId}</p>}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>

                <Container>
                    <div className='details'>Address Details</div>
                    <Form>
                        <Row>
                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Street Address
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.address}
                                            type='text'
                                            placeholder='e.g. St #12'
                                            name='address'
                                            value={inputData.address}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.address && <p className="error">{errorMessage.address}</p>}
                                    </label>
                                </div>
                            </Col>

                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            City
                                            <span>*</span>
                                        </div>
                                        <Form.Select
                                            ref={refs.city}
                                            name="city"
                                            value={inputData.city}
                                            onChange={handleInput}
                                            required
                                        >
                                            <option value="">Select</option>
                                            {allCities.map((option) => (
                                                <option key={option.id} value={option.name}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {errorMessage.city && <p className="error">{errorMessage.city}</p>}
                                    </label>
                                </div>
                            </Col>

                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            State
                                            <span>*</span>
                                        </div>
                                        <Form.Select
                                            ref={refs.state}
                                            name="state"
                                            value={inputData.state}
                                            onChange={handleInput}
                                            required
                                        >
                                            <option value="">Select</option>
                                            {allStates.map((option) => (
                                                <option key={option.isoCode} value={option.name}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {errorMessage.state && <p className="error">{errorMessage.state}</p>}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>

                <Container>
                    <div className='details'>Contact Details</div>
                    <Form>
                        <Row>
                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Phone Number
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.phone}
                                            type='text'
                                            placeholder='Enter phone number'
                                            name='phone'
                                            value={inputData.phone}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.phone && <p className="error">{errorMessage.phone}</p>}
                                    </label>
                                </div>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Personal Email
                                        </div>
                                        <input
                                            type='email'
                                            placeholder='Enter email address'
                                            name='email'
                                            value={inputData.email}
                                            onChange={handleInput} />
                                    </label>
                                </div>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Government ID
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.government_id}
                                            type='text'
                                            placeholder='e.g. Driving License, Passport, National ID'
                                            name='government_id'
                                            value={inputData.government_id}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.government_id && <p className="error">{errorMessage.government_id}</p>}
                                    </label>
                                </div>
                            </Col>

                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Secondary Phone Number
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Enter phone number'
                                            name='secondary_phone'
                                            value={inputData.secondary_phone}
                                            onChange={handleInput}
                                        />
                                    </label>
                                </div>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Official Email Address
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Enter email address'
                                            name='official_email'
                                            value={inputData.official_email}
                                            onChange={handleInput}
                                        />
                                    </label>
                                </div>

                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Next of Kin
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.kin}
                                            type='text'
                                            name='kin'
                                            value={inputData.kin}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.kin && <p className="error">{errorMessage.kin}</p>}
                                    </label>
                                </div>
                            </Col>

                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            BVN Number
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Enter 11-digit BVN number'
                                            name='bvn'
                                            value={inputData.bvn}
                                            onChange={handleInput}
                                        />
                                    </label>
                                </div>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Date of Birth (DOB)
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.dob}
                                            type='text'
                                            placeholder='Enter date of birth'
                                            name='dob'
                                            value={inputData.dob}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.dob && <p className="error">{errorMessage.dob}</p>}
                                    </label>
                                </div>

                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Next of kin Phone Number
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.kin_phone}
                                            type='text'
                                            name='kin_phone'
                                            value={inputData.kin_phone}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.kin_phone && <p className="error">{errorMessage.kin_phone}</p>}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>

                <Container>
                    <Form>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div>
                                    <button
                                        type='button'
                                        className='disabled_back'
                                    >
                                        Back
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => handleSubmit(1)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </>
    )
}

export default PersonalContact
