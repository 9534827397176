import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import config from '../../config/configuration_keys.json';

const PageBanner = ({ investBanners }) => {
    const REACT_APP_API_URL = config.REACT_APP_API_URL;
    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${REACT_APP_API_URL}/uploads/${investBanners && investBanners?.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100%',
                }}
                className='contact_banner'>
                <Container >
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className="heading">
                                <h1>{investBanners && investBanners?.title}</h1>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} className='box-ct-paragraph'>
                            <div className="description">
                                <p>{investBanners && investBanners?.description}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default PageBanner
