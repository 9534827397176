import React, { useState, useRef } from 'react'
import axios from 'axios';
import MessageModal from '../../components/MessageModal';
import config from '../../config/configuration_keys.json';

const FormContact = () => {
    const data = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        message: ""
    }
    const [inputData, setData] = useState(data);
    const [submitted, setSubmitted] = useState();
    const [tittle, setTittle] = useState();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const REACT_APP_API_URL = config.REACT_APP_API_URL;

    const refs = {
        first_name: useRef(null),
        last_name: useRef(null),
        phone: useRef(null),
        email: useRef(null),
        message: useRef(null),
    };


    function handleInput(e) {
        setData({ ...inputData, [e.target.name]: e.target.value });
        setErrorMessage({ ...errorMessage, [e.target.name]: null });
    }

    const validateForm = () => {
        setSubmitted(true);
        let errors = {};

        if (!inputData.first_name) {
            errors.first_name = 'Please enter your First Name.';
        }

        if (!inputData.last_name) {
            errors.last_name = 'Please enter your Last Name.';
        }

        if (!inputData.phone) {
            errors.phone = 'Please enter your Phone Number.';
        } else if (inputData.phone.length < 9 || inputData.phone.length > 15) {
            errors.phone = 'Phone Number must be between 9 and 15 digits.';
        }

        if (!inputData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputData.email)) {
            errors.email = 'Please enter a valid E-mail Address.';
        }

        if (!inputData.message) {
            errors.message = 'Please enter your Message.';
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm(inputData);
        setErrorMessage(errors);
        if (Object.keys(errors).length === 0) {
            setIsLoading(true)
            try {
                const response = await axios.post(`${REACT_APP_API_URL}/admin/contactUs/sent_mail`, inputData);
                if (response?.status === 200) {
                    setShowModal(true);
                    setIsLoading(false);
                    setSuccessMessage(response.data)
                    setTittle('Success')
                } else {
                    setShowModal(true);
                    setIsLoading(false);
                    setSuccessMessage('Something Went Wrong')
                    setTittle('Error')
                }
            } catch (error) {
                setShowModal(true);
                setIsLoading(false);
                setSuccessMessage('Something Went Wrong')
                setTittle('Error')
            }
        } else {
            const firstErrorField = Object.keys(errors).find((field) => errors[field]);
            if (firstErrorField && refs[firstErrorField]?.current) {
                refs[firstErrorField].current.focus();
                refs[firstErrorField].current.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setData(data);
        setSubmitted(false);
        setErrorMessage('');
    };

    return (
        <>
            {showModal ? (
                <MessageModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    message={successMessage}
                    title={tittle}
                    closeButtonText={'Close'}
                />
            ) : ""}
            <div className='my_contact'>
                <div className='container'>
                    <div className='row gx-5'>
                        <div className='col-lg-6'>
                            <div className='map'>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.0845376340612!2d3.350576715293652!3d6.510983425113825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8c424cba499d%3A0xdb089b92c1a29a86!2sMicro%20Leasing%20Limited!5e0!3m2!1sen!2sng!4v1623077802699!5m2!1sen!2sng"
                                    title="Map showing Micro Leasing Limited location"
                                ></iframe>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='set'>
                                <h2>Contact US</h2>
                                <h3>We will get in touch with you shortly</h3>
                                <div className='row'>
                                    <h4>Your Name</h4>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <input
                                                ref={refs.first_name}
                                                type='text'
                                                name='first_name'
                                                value={inputData.first_name}
                                                onChange={handleInput}
                                                className='form-control'
                                                placeholder='First Name'
                                            />
                                            {submitted && errorMessage.first_name && <p className="error">{errorMessage.first_name}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <input
                                                ref={refs.last_name}
                                                type='text'
                                                name='last_name'
                                                value={inputData.last_name}
                                                onChange={handleInput}
                                                className='form-control'
                                                placeholder='Last Name'
                                            />
                                            {submitted && errorMessage.last_name && <p className="error">{errorMessage.last_name}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Phone Number</label>
                                            <input
                                                ref={refs.phone}
                                                type='number'
                                                placeholder='(000) 000-0000'
                                                name='phone'
                                                value={inputData.phone}
                                                onChange={handleInput}
                                                className='form-control'
                                                onWheel={(e) => {
                                                    e.preventDefault();
                                                    e.target.blur();
                                                }}
                                            />
                                            {submitted && errorMessage.phone && <p className="error">{errorMessage.phone}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Your E-mail Address</label>
                                            <input
                                                ref={refs.email}
                                                type='email'
                                                placeholder='ex: myname@example.com'
                                                name='email'
                                                value={inputData.email}
                                                onChange={handleInput}
                                                className='form-control'
                                            />
                                            {submitted && errorMessage.email && <p className="error">{errorMessage.email}</p>}
                                        </div>
                                    </div>

                                    <div className='col-12'>
                                        <div className='form-group'>
                                            <label>Leave Your Message</label>
                                            <textarea
                                                ref={refs.message}
                                                type='text'
                                                placeholder="Write your message here"
                                                name='message'
                                                value={inputData.message}
                                                onChange={handleInput}
                                                required
                                                className='form-control'
                                            />
                                            {submitted && errorMessage.message && <p className="error">{errorMessage.message}</p>}
                                        </div>
                                    </div>

                                    <div className='col-12 text-center'>
                                        <button
                                            type='submit'
                                            onClick={handleSubmit}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Submitted...' : 'Submit'}
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FormContact
