import React, { useRef, useEffect } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import MessageModal from '../../components/MessageModal';

const LoanContact = ({
    inputData,
    setInputData,
    handleSubmit,
    handleBack,
    submitted,
    errorMessage,
    handleCloseModal,
    showModal,
    successMessage,
    tittle, 
    setErrorMessage
}) => {
    // Refs for each input field
    const refs = {
        loan_amount: useRef(null),
        reason: useRef(null),
        duration: useRef(null),
        installment: useRef(null),
    };

    const allReason = [
        { id: 1, name: 'PL' },
        { id: 2, name: 'Car Loan' },
        { id: 3, name: 'Bike Loan' },
    ];

    const allDuration = [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
    ];

    const handleInput = (event) => {
        const { name, value } = event.target;
        // Update only the specific field, preserving the rest of the data
        setInputData(prevState => ({
            ...prevState,
            [name]: value,  // Update only the field that was changed
        }));
        setErrorMessage({ ...errorMessage, [event.target.name]: null });
    };

    useEffect(() => {
        const storedData = localStorage.getItem('formData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setInputData(parsedData);
        }
    }, [setInputData]);

    return (
        <>
            {showModal ? (
                <MessageModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    message={successMessage}
                    title={tittle}
                    closeButtonText={'Close'}
                />
            ) : ""}
            <div className='loan_contact'>
                <Container>
                    <div className='details'>Loan Details</div>
                    <Form>
                        <Row>
                            <Col lg={4} md={4} sm={12}>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Loan Amount
                                            <span>*</span>
                                        </div>
                                        <div className='input-dollar'>
                                            <input
                                                ref={refs.loan_amount}
                                                type='text'
                                                placeholder='0.00'
                                                name='loan_amount'
                                                value={inputData.loan_amount}
                                                onChange={handleInput}
                                                className='dollar_input'
                                            />
                                        </div>

                                        {errorMessage?.loan_amount && <p className="error">{errorMessage.loan_amount}</p>}
                                    </label>
                                </div>

                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Reason for Loan
                                            <span>*</span>
                                        </div>
                                        <Form.Select
                                            ref={refs.reason}
                                            name="reason"
                                            value={inputData.reason}
                                            onChange={handleInput}
                                            required
                                        >
                                            <option value="">Select reason</option>
                                            {allReason.map((option) => (
                                                <option key={option.id} value={option.name}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {errorMessage?.reason && <p className="error">{errorMessage.reason}</p>}
                                    </label>
                                </div>
                            </Col>

                            <Col lg={4} md={4} sm={12}>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Loan duration (Months)
                                            <span>*</span>
                                        </div>
                                        <Form.Select
                                            ref={refs.duration}
                                            name="duration"
                                            value={inputData.duration}
                                            onChange={handleInput}
                                            required
                                        >
                                            <option value="">Select Months</option>
                                            {allDuration.map((option) => (
                                                <option key={option.id} value={option.name}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        { errorMessage?.duration && <p className="error">{errorMessage.duration}</p>}
                                    </label>
                                </div>

                            </Col>

                            <Col lg={4} md={4} sm={12}>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Monthly Installment
                                            <span>*</span>
                                        </div>
                                        <div className='input-dollar'>
                                            <input
                                                ref={refs.installment}
                                                type='text'
                                                placeholder='0.00'
                                                name='installment'
                                                value={inputData.installment}
                                                onChange={handleInput}
                                                className='dollar_input'
                                            />
                                        </div>
                                        { errorMessage?.installment && <p className="error">{errorMessage.installment}</p>}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>

                <Container>
                    <Form>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div>
                                    <button
                                        type='button'
                                        onClick={handleBack}
                                        className={submitted ? 'disabled_back' : 'back_btn'}
                                        disabled={submitted ? true : false}
                                    >
                                        Back
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => handleSubmit(5)}
                                        disabled={submitted ? true : false}
                                    >
                                        {submitted ? "Submitted..." : 'Submit'}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </>
    )
}

export default LoanContact
