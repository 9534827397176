import React from 'react';

const PeepeDem = (props) => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.15" width="55.6564" height="55.6564" rx="6" fill="#069BBC" />
        <path d="M13.8776 36.0947H36.8474C37.0534 36.0947 37.2525 36.0226 37.4104 35.8912C37.631 35.7065 42.6637 31.3771 42.9706 22.0316H18.3015C17.9958 30.5148 13.361 34.5022 13.3128 34.542C13.0304 34.7806 12.9265 35.1703 13.0536 35.5171C13.1797 35.8629 13.5085 36.0947 13.8776 36.0947ZM42.121 16.7579H37.7263V15.8789C37.7263 15.3867 37.3395 15 36.8473 15C36.3551 15 35.9684 15.3867 35.9684 15.8789V16.7579H31.5151V15.8789C31.5151 15.3867 31.1283 15 30.6361 15C30.1439 15 29.7572 15.3867 29.7572 15.8789V16.7579H25.3624V15.8789C25.3624 15.3867 24.9757 15 24.4835 15C23.9913 15 23.6045 15.3867 23.6045 15.8789V16.7579H19.2098C18.7176 16.7579 18.3309 17.1446 18.3309 17.6368V20.2737H42.9999V17.6368C42.9999 17.1446 42.6132 16.7579 42.121 16.7579Z" fill="#039EC0" />
        <path d="M38.5383 37.2389C38.0611 37.6363 37.462 37.8526 36.8465 37.8526H18.3301V40.4895C18.3301 40.9753 18.7232 41.3684 19.209 41.3684H42.1202C42.606 41.3684 42.9992 40.9753 42.9992 40.4895V30.6367C41.3045 34.7942 38.9398 36.9028 38.5383 37.2389Z" fill="#039EC0" />
    </svg>
);

export default PeepeDem;