import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css'
import config from '../../config/configuration_keys.json';
import { EffectFade } from 'swiper/modules';
import { Link } from 'react-router-dom';
const Banner = ({ banners }) => {
    const REACT_APP_API_URL = config.REACT_APP_API_URL;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <div className="home-banner">
                <Swiper
                    loop={true}
                    effect="fade"
                    speed={2000}
                    autoplay={{
                        delay: 2000, // Set the time interval for automatic sliding (in ms)
                        disableOnInteraction: false, // Ensures autoplay continues after interaction
                    }}
                    pagination={{
                        el: ".swiper-pagination",
                        type: "fraction",
                        clickable: true,
                        draggable: true
                    }}
                    navigation={{
                        nextEl: ".swiper-button-next-banner",
                        prevEl: ".swiper-button-prev-banner",
                    }}
                    modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
                    className="swiper mySwiper home-slider">
                    {banners && banners.map((banner, index) => (
                        <SwiperSlide
                            key={index}
                            className={`slide-${index + 1}`}
                            style={{
                                backgroundImage: `url(${REACT_APP_API_URL}/uploads/${banner?.image})`, // Dynamic background image
                                backgroundSize: 'cover', // Adjust as needed
                                backgroundPosition: 'center',
                                height: '100vh', // Adjust height if necessary
                            }}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                        <div className="home-bt-inner-ct">
                                            <h2>{banner.text}</h2>
                                            <div className="button_get_loan_banking">
                                                <Link to={'/loan'} title="Get a Loan" className="get_loan">Get a Loan</Link>
                                                {/* <Link to={'/loan'} title="Online Banking" className="online_banking">Online Banking</Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                    <div className="home-banner-show-pt">
                        <div className="swiper-pagination" />
                        <div className="inner-next-prev">
                            <div className="swiper-button-next swiper-button-next-banner">
                                <svg
                                    width={23}
                                    height={7}
                                    viewBox="0 0 23 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M23 3.5L17.8463 0V2.89655L0 2.89655V4.10345L17.8463 4.10345V7L23 3.5Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                            <div className="swiper-button-prev swiper-button-prev-banner">
                                <svg
                                    width={23}
                                    height={7}
                                    viewBox="0 0 23 7"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0 3.5L5.15374 0V2.89655L23 2.89655V4.10345L5.15374 4.10345V7L0 3.5Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </Swiper>
            </div>
        </>
    )
}

export default Banner
