import React, { useState, useCallback, useEffect } from 'react'
import ContactBanner from '../My-comonent/FAQPageComponent/ContactBanner'
import OneShortTram from '../My-comonent/OneShortTearm'
import FormContact from '../My-comonent/FAQPageComponent/FormContact'
import axios from 'axios';
import config from '../config/configuration_keys.json';
import Loader from '../components/loader';

const Contact = (props) => {
    const [dataSections, setDataSections] = useState([
        {
            id: null,
            image: '',
            tittle: '',
            description: '',
            tempImageUrl: '',
            section_name: 'Main'
        },
        {
            id: null,
            section_name: 'Additional Resources',
            resources: Array(9).fill(null).map((_, index) => ({
                position: index,
                title: '',
                content: '',
                ckeditor_content: '',
                custom_position: ''
            }))
        }
    ]);
    const [isLoading, setIsLoading] = useState(true);
    const REACT_APP_API_URL = config.REACT_APP_API_URL;

    const fetchFaqSections = useCallback(async () => {
        try {
            const response = await axios.get(`${REACT_APP_API_URL}/admin/faq/getFAQSections`);
            if (response.data.length > 0) {
                const transformedData = transformBackendData(response.data);
                setDataSections(transformedData);
                setIsLoading(false);
            } else {
                setDataSections([
                    { id: null, image: '', tittle: '', description: '', tempImageUrl: '', section_name: 'Main' },
                    {
                        id: null,
                        section_name: 'Additional Resources',
                        resources: Array(9).fill(null).map((_, index) => ({
                            position: index,
                            title: '',
                            content: '',
                            ckeditor_content: '',
                            custom_position: ''
                        }))
                    }
                ]);
            }
        } catch (error) {
            console.error('Error fetching Choose Us sections:', error);
        }
    }, [REACT_APP_API_URL]);

    const transformBackendData = (data) => {
        return data.map(section => {
            if (section.section_name === 'Main') {
                return {
                    id: section?.id,
                    image: section?.image || '',
                    tittle: section?.tittle || '',
                    description: section?.description || '',
                    section_name: 'Main'
                };
            } else if (section.section_name === 'Additional Resources') {
                // Parse the resources string
                let parsedResources = [];
                try {
                    parsedResources = JSON.parse(section.resources || '[]');
                } catch (error) {
                    console.log('Error parsing resources:', error);
                }

                // Transform resources to match your state structure
                const transformedResources = Array(9).fill(null).map((_, index) => {
                    const resource = parsedResources.find(r => r.position - 1 === index);
                    return {
                        position: index + 1,
                        title: resource?.title || '',
                        content: resource?.content || '',
                        ckeditor_content: resource?.ckeditor_content || '',
                        custom_position: resource?.customPosition || ''
                    };
                });

                return {
                    id: section.id,
                    section_name: 'Additional Resources',
                    resources: transformedResources
                };
            }
            return section;
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchFaqSections();
    }, [fetchFaqSections]);


    return (
        <div className='contact-page' >
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <ContactBanner dataSections={dataSections} />
                    <FormContact dataSections={dataSections} />
                </>
            )}
            <OneShortTram />
        </div>
    )
}

export default Contact
