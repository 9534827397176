import React, { useEffect } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const EmploymentContact = ({
    inputData,
    setInputData,
    handleFileChange,
    handleSubmit,
    submitted,
    errorMessage,
    setErrorMessage,
    handleBack,
    refs
}) => {
    const allCities = [
        { id: 1, name: 'Mumbai' },
        { id: 2, name: 'Delhi' },
        { id: 3, name: 'Bangalore' },
    ];

    const allDegree = [
        { id: 1, name: 'MBA' },
        { id: 2, name: 'B.Com' },
        { id: 3, name: 'B.A.' },
    ];

    const allPosition = [
        { id: 1, name: 'Team Leader' },
        { id: 2, name: 'Employ' },
        { id: 3, name: 'HR' },
    ];

    const allStates = [
        { isoCode: 'AP', name: 'Andhra Pradesh' },
        { isoCode: 'UP', name: 'Uttar Pradesh' },
        { isoCode: 'MH', name: 'Maharashtra' },
    ];

    const handleInput = (event) => {
        const { name, value } = event.target;
        setErrorMessage((prevErrors) => ({
            ...prevErrors,
            [name]: '', 
        }));
        setInputData(prevState => ({
            ...prevState,
            [name]: value, 
        }));
    };

    useEffect(() => {
        const storedData = localStorage.getItem('formData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setInputData(parsedData);
        }
    }, [setInputData]);

    return (
        <>
            <div className='loan_contact'>
                <Container>
                    <div className='details'>Employer Details</div>
                    <Form>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Employer Name
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.employer_name}
                                            type='text'
                                            placeholder='Enter employer name'
                                            name='employer_name'
                                            value={inputData.employer_name}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.employer_name && <p className="error">{errorMessage.employer_name}</p>}
                                    </label>
                                </div>
                            </Col>

                            <div className="row m-0 p-0">

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Date of Employment
                                                <span>*</span>
                                            </div>
                                            <input
                                                ref={refs.employment_dob}
                                                type='text'
                                                placeholder='MM/DD/YYYY'
                                                name='employment_dob'
                                                value={inputData.employment_dob}
                                                onChange={handleInput}
                                                required
                                            />
                                            {errorMessage.employment_dob && <p className="error">{errorMessage.employment_dob}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Department
                                                <span>*</span>
                                            </div>
                                            <input
                                                ref={refs.department_name}
                                                type='text'
                                                placeholder='Enter department name'
                                                name='department_name'
                                                value={inputData.department_name}
                                                onChange={handleInput}
                                                required
                                            />
                                            {errorMessage.department_name && <p className="error">{errorMessage.department_name}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box sum-box-choose'>
                                        <label>
                                            <div className='loan_title'>
                                                Official ID Card
                                                <span>*</span>
                                            </div>
                                            <input
                                                ref={refs.officialId}
                                                type="file"
                                                accept="image/*"
                                                name="officialId"
                                                onChange={handleFileChange}
                                                required
                                                className="input-field"
                                            />
                                            {inputData?.files?.officialId?.name ?? (
                                                <div className='loan_title'>
                                                    {inputData?.officialId?.name}
                                                </div>
                                            )}
                                            {errorMessage.officialId && <p className="error">{errorMessage.officialId}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Supervisor`s name
                                            </div>
                                            <input
                                                type='text'
                                                placeholder='Enter Supervisor`s name'
                                                name='supervisor_name'
                                                value={inputData.supervisor_name}
                                                onChange={handleInput}
                                                required
                                            />
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Employer Main Phone Number
                                                <span>*</span>
                                            </div>
                                            <input
                                                ref={refs.employer_phone}
                                                type='text'
                                                placeholder='Enter employer main phone number'
                                                name='employer_phone'
                                                value={inputData.employer_phone}
                                                onChange={handleInput}
                                                required
                                            />
                                            {errorMessage.employer_phone && <p className="error">{errorMessage.employer_phone}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Your Extension or Direct line
                                                <span>*</span>
                                            </div>
                                            <input
                                                ref={refs.direct_line}
                                                type='text'
                                                placeholder='Enter extension or direct line'
                                                name='direct_line'
                                                value={inputData.direct_line}
                                                onChange={handleInput}
                                                required
                                            />
                                            {errorMessage.direct_line && <p className="error">{errorMessage.direct_line}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Salary Account
                                                <span>*</span>
                                            </div>
                                            <input
                                                ref={refs.account_name}
                                                type='text'
                                                placeholder='Enter account name'
                                                name='account_name'
                                                value={inputData.account_name}
                                                onChange={handleInput}
                                                required
                                            />
                                            {errorMessage.account_name && <p className="error">{errorMessage.account_name}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Salary Account Number
                                                <span>*</span>
                                            </div>
                                            <input
                                                ref={refs.account_number}
                                                type='text'
                                                placeholder='Enter account number'
                                                name='account_number'
                                                value={inputData.account_number}
                                                onChange={handleInput}
                                                required
                                            />
                                            {errorMessage.account_number && <p className="error">{errorMessage.account_number}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Date Salary Account was opened
                                                <span>*</span>
                                            </div>
                                            <input
                                                ref={refs.account_opened}
                                                type='text'
                                                placeholder='MM/DD/YYYY'
                                                name='account_opened'
                                                value={inputData.account_opened}
                                                onChange={handleInput}
                                                required
                                            />
                                            {errorMessage.account_opened && <p className="error">{errorMessage.account_opened}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Monthly Salary Amount
                                                <span>*</span>
                                            </div>
                                            <input
                                                ref={refs.salary_amount}
                                                type='text'
                                                placeholder='Enter net monthly income'
                                                name='salary_amount'
                                                value={inputData.salary_amount}
                                                onChange={handleInput}
                                                required
                                            />
                                            {errorMessage.salary_amount && <p className="error">{errorMessage.salary_amount}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Salary Paydate
                                                <span>*</span>
                                            </div>
                                            <input
                                                ref={refs.pay_date}
                                                type='text'
                                                placeholder='MM/DD/YYYY'
                                                name='pay_date'
                                                value={inputData.pay_date}
                                                onChange={handleInput}
                                                required
                                            />
                                            {errorMessage.pay_date && <p className="error">{errorMessage.pay_date}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Position Within Company
                                                <span>*</span>
                                            </div>
                                            <Form.Select
                                                ref={refs.company_position}
                                                name="company_position"
                                                value={inputData.company_position}
                                                onChange={handleInput}
                                                required
                                            >
                                                <option value="">Select position</option>
                                                {allPosition.map((option) => (
                                                    <option key={option.id} value={option.name}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {errorMessage.company_position && <p className="error">{errorMessage.company_position}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box' id='radio-wr'>
                                        <div className='loan_title'>
                                            Have you been confirmed?
                                            <span>*</span>
                                        </div>
                                        <div className={`input_box choose-field ${inputData.confirmed_radio === "yes" ? 'first-tab-radio' : ''}`}>
                                            <input
                                                ref={refs.confirmed_radio}
                                                className="form-check-input"
                                                type="radio"
                                                name="confirmed_radio"
                                                id="flexRadioDefault11"
                                                checked={inputData.confirmed_radio === "yes"}
                                                value="yes"
                                                onChange={handleInput}
                                            />
                                            <label className="form-check-label" for="flexRadioDefault11">Yes</label>
                                        </div>
                                        {errorMessage.confirmed_radio && <p className="error">{errorMessage.confirmed_radio}</p>}
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className={`input_box choose-field ${inputData.confirmed_radio === "no" ? 'second-tab-radio' : ''}`}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="confirmed_radio"
                                            id="flexRadioDefault1"
                                            value="no"
                                            onChange={handleInput}
                                            checked={inputData.confirmed_radio === "no"}
                                        />
                                        <label className="form-check-label" for="flexRadioDefault1">No</label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'></div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                Degree
                                                <span>*</span>
                                            </div>
                                            <Form.Select
                                                ref={refs.degree}
                                                name="degree"
                                                value={inputData.degree}
                                                onChange={handleInput}
                                                required
                                            >
                                                <option value="">Select degree</option>
                                                {allDegree.map((option) => (
                                                    <option key={option.id} value={option.name}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {errorMessage.degree && <p className="error">{errorMessage.degree}</p>}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                PFA
                                            </div>
                                            <input
                                                type='text'
                                                name='pfa'
                                                value={inputData.pfa}
                                                onChange={handleInput}
                                            />
                                        </label>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-6'>
                                    <div className='input_box'>
                                        <label>
                                            <div className='loan_title'>
                                                PenCom Id Number
                                            </div>
                                            <input
                                                type='text'
                                                name='penCom'
                                                value={inputData.penCom}
                                                onChange={handleInput}
                                            />
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </Row>
                    </Form>
                </Container>

                <Container>
                    <div className='details'>Address Details</div>
                    <Form>
                        <Row>
                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Street Address
                                            <span>*</span>
                                        </div>
                                        <input
                                            ref={refs.employer_address}
                                            type='text'
                                            placeholder='e.g. St #12'
                                            name='employer_address'
                                            value={inputData.employer_address}
                                            onChange={handleInput}
                                            required
                                        />
                                        {errorMessage.employer_address && <p className="error">{errorMessage.employer_address}</p>}
                                    </label>
                                </div>

                            </Col>

                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            City
                                            <span>*</span>
                                        </div>
                                        <Form.Select
                                            ref={refs.employer_city}
                                            name="employer_city"
                                            value={inputData.employer_city}
                                            onChange={handleInput}
                                            required
                                        >
                                            <option value="">Select</option>
                                            {allCities.map((option) => (
                                                <option key={option.id} value={option.name}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {errorMessage.employer_city && <p className="error">{errorMessage.employer_city}</p>}
                                    </label>
                                </div>
                            </Col>

                            <Col lg={4} md={4} sm={12} className='box-input-form'>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            State
                                            <span>*</span>
                                        </div>
                                        <Form.Select
                                            ref={refs.employer_state}
                                            name="employer_state"
                                            value={inputData.employer_state}
                                            onChange={handleInput}
                                            required
                                        >
                                            <option value="">Select</option>
                                            {allStates.map((option) => (
                                                <option key={option.isoCode} value={option.name}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {errorMessage.employer_state && <p className="error">{errorMessage.employer_state}</p>}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>

                <Container>
                    <Form>
                        <Row>
                            <Col lg={12} md={12} sm={12} className='box-input-form'>
                                <div>
                                    <button
                                        type='button'
                                        className='back_btn'
                                        onClick={handleBack}
                                    >
                                        Back
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => handleSubmit(2)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </>
    )
}

export default EmploymentContact
