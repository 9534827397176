import React from 'react'
import { Link } from 'react-router-dom'

const OneShortTram = () => {
  return (
    <div className="home-one-short-tearm">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="inner-short-ct">
              <h2 data-aos="fade-up" data-aos-duration={500}>One short form</h2>
              <h3 data-aos="fade-up" data-aos-duration={700}>It only takes 5 minutes</h3>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="home_proceed_to_form">
              <p data-aos="fade-up" data-aos-duration={500}>It only takes 5 minutes to secure the financial support you need for life's unexpected moments.</p>
              <div className="form-pr" data-aos="fade-up" data-aos-duration={700}>
                <Link to="/loan" title="Proceed to Form">Proceed to Form</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OneShortTram