import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import config from '../../config/configuration_keys.json';

const ContactBanner = ({ dataSections }) => {
    const REACT_APP_API_URL = config.REACT_APP_API_URL;
    let backgroundImage = dataSections[0]?.image || 'N/A'
    return (
        <>
            <div className='contact_banner'
                style={{
                    backgroundImage: backgroundImage !== 'N/A' ? `url(${REACT_APP_API_URL}/uploads/${backgroundImage})` : '',
                }}
            >
                <Container >
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className="heading">
                                <h1>{dataSections && dataSections[0]?.tittle}</h1>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} className='box-ct-paragraph'>
                            <div className="description">
                                <p>
                                    {dataSections && dataSections[0]?.description}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ContactBanner
