import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Col, Container, Row } from 'react-bootstrap'
import config from '../../config/configuration_keys.json';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Link } from 'react-router-dom';

const HomeChooseRight = ({ homeBanners }) => {
    const REACT_APP_API_URL = config.REACT_APP_API_URL;

    return (
        <>
            <div className="home-choose-right">
                <Container>
                    <Row className='choose-inner-ct-st'>
                        <Col xl={6} lg={6} md={6} sm={12}>
                            <h2 data-aos="fade-up" data-aos-duration={500}>
                                Choose what's right for you
                            </h2>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12}>
                            <p data-aos="fade-up" data-aos-duration={700}>
                                Choose what’s right for you from our range of options. Tailor your
                                selection to fit your specific needs and preferences.
                            </p>
                        </Col>
                    </Row>
                    <Swiper className='choose-slider'
                        spaceBetween={50}
                        loop={true}
                        slidesPerView={3}
                        navigation={{
                            nextEl: ".choose-next",
                            prevEl: ".choose-prev",
                        }}
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        breakpoints={{
                            320: { // mobile
                                slidesPerView: 1,
                            },
                            768: { // medium devices
                                slidesPerView: 2,
                            },
                            1200: { // desktop
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {homeBanners && homeBanners.map((banner, index) => (
                            <SwiperSlide>
                                <div className="what-right-box business_box">
                                    <div className="header-bar">
                                        <h3>{banner?.title}</h3>
                                        <div className="icon">
                                            <img src={`${REACT_APP_API_URL}/uploads/${banner?.icon}`} height="37" width="39" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="body-box">
                                        <p>
                                            {banner?.description.split('\r\n').map((line, index) => {
                                                if (index === 0) {
                                                    return line; // Keep the first part as a regular paragraph text
                                                }
                                                return (
                                                    <ul key={index}>
                                                        <li>{line}</li>
                                                    </ul>
                                                );
                                            })}
                                        </p>
                                        <div className="learn-more-button">
                                            <Link to="/contact" title="Learn more">
                                                <div className="icon">
                                                    <svg
                                                        width={37}
                                                        height={37}
                                                        viewBox="0 0 37 37"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle cx="18.5" cy="18.5" r="18.5" fill="#A51C30" />
                                                        <path
                                                            d="M20.8458 13.1992C20.7959 13.1401 20.7341 13.0919 20.6643 13.0577C20.5945 13.0235 20.5182 13.004 20.4404 13.0006C20.3626 12.9971 20.2848 13.0097 20.2122 13.0375C20.1396 13.0654 20.0736 13.1079 20.0185 13.1623C19.9634 13.2167 19.9204 13.2819 19.8922 13.3537C19.864 13.4254 19.8513 13.5022 19.8548 13.5791C19.8583 13.656 19.878 13.7313 19.9126 13.8003C19.9473 13.8693 19.996 13.9304 20.0559 13.9797L24.0862 17.9661H10.5326C10.3888 17.9736 10.2534 18.0354 10.1543 18.1386C10.0552 18.2418 10 18.3787 10 18.521C10 18.6632 10.0552 18.8001 10.1543 18.9033C10.2534 19.0066 10.3888 19.0683 10.5326 19.0758H24.0862L20.0496 23.0576C19.948 23.1627 19.8913 23.3025 19.8913 23.4478C19.8913 23.5931 19.948 23.7329 20.0496 23.838C20.1014 23.8894 20.1629 23.9301 20.2307 23.9579C20.2985 23.9857 20.3711 24 20.4445 24C20.5179 24 20.5906 23.9857 20.6584 23.9579C20.7261 23.9301 20.7877 23.8894 20.8395 23.838L25.832 18.9057C25.8851 18.8552 25.9274 18.7946 25.9562 18.7275C25.9851 18.6605 26 18.5884 26 18.5155C26 18.4426 25.9851 18.3705 25.9562 18.3035C25.9274 18.2364 25.8851 18.1758 25.832 18.1253L20.8458 13.1992Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                    <span>Learn more</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="inner-slide-button">
                        <div className="swiper-button-prev choose-prev">
                            <svg
                                width={51}
                                height={51}
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="25.5" cy="25.5" r={25} fill="white" stroke="#E3E3E3" />
                                <path
                                    d="M22.087 18.2717C22.1556 18.1911 22.2406 18.1253 22.3366 18.0787C22.4326 18.032 22.5374 18.0055 22.6444 18.0008C22.7515 17.996 22.8583 18.0132 22.9582 18.0512C23.0581 18.0891 23.1488 18.1471 23.2246 18.2213C23.3003 18.2955 23.3595 18.3844 23.3982 18.4823C23.437 18.5801 23.4545 18.6848 23.4497 18.7897C23.4448 18.8946 23.4177 18.9973 23.3701 19.0913C23.3225 19.1854 23.2554 19.2687 23.1732 19.3359L17.6315 24.7719H36.2676C36.4654 24.7822 36.6515 24.8664 36.7878 25.0072C36.924 25.1479 37 25.3346 37 25.5286C37 25.7226 36.924 25.9092 36.7878 26.05C36.6515 26.1908 36.4654 26.275 36.2676 26.2852H17.6315L23.1819 31.7149C23.3216 31.8582 23.3995 32.0488 23.3995 32.247C23.3995 32.4452 23.3216 32.6358 23.1819 32.7791C23.1106 32.8491 23.026 32.9047 22.9328 32.9426C22.8396 32.9805 22.7397 33 22.6388 33C22.5379 33 22.438 32.9805 22.3448 32.9426C22.2516 32.9047 22.1669 32.8491 22.0957 32.7791L15.231 26.0532C15.158 25.9843 15.0999 25.9017 15.0602 25.8103C15.0205 25.7188 15 25.6205 15 25.5211C15 25.4218 15.0205 25.3234 15.0602 25.232C15.0999 25.1406 15.158 25.0579 15.231 24.989L22.087 18.2717Z"
                                    fill="#A51C30"
                                />
                            </svg>
                        </div>
                        <div className="swiper-button-next choose-next">
                            <svg
                                width={59}
                                height={59}
                                viewBox="0 0 59 59"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="29.5"
                                    cy="29.5"
                                    r={29}
                                    transform="matrix(-1 0 0 1 59 0)"
                                    fill="white"
                                    stroke="#E3E3E3"
                                />
                                <path
                                    d="M33.6245 20.3441C33.5434 20.242 33.4429 20.1587 33.3295 20.0997C33.216 20.0406 33.0921 20.007 32.9657 20.001C32.8392 19.995 32.7129 20.0167 32.5948 20.0648C32.4768 20.1129 32.3696 20.1863 32.2801 20.2803C32.1905 20.3743 32.1206 20.4869 32.0748 20.6109C32.029 20.7348 32.0083 20.8675 32.014 21.0003C32.0198 21.1331 32.0518 21.2632 32.108 21.3824C32.1643 21.5015 32.2436 21.607 32.3408 21.6921L38.8901 28.5778H16.8655C16.6319 28.5908 16.4118 28.6974 16.2508 28.8758C16.0898 29.0541 16 29.2905 16 29.5362C16 29.7819 16.0898 30.0183 16.2508 30.1966C16.4118 30.375 16.6319 30.4816 16.8655 30.4946H38.8901L32.3305 37.3722C32.1654 37.5538 32.0733 37.7951 32.0733 38.0462C32.0733 38.2972 32.1654 38.5386 32.3305 38.7202C32.4147 38.8089 32.5148 38.8793 32.6249 38.9273C32.735 38.9753 32.8531 39 32.9724 39C33.0916 39 33.2097 38.9753 33.3198 38.9273C33.43 38.8793 33.53 38.8089 33.6142 38.7202L41.7269 30.2008C41.8133 30.1135 41.882 30.0088 41.9289 29.893C41.9758 29.7772 42 29.6526 42 29.5268C42 29.4009 41.9758 29.2763 41.9289 29.1605C41.882 29.0447 41.8133 28.94 41.7269 28.8528L33.6245 20.3441Z"
                                    fill="#A51C30"
                                />
                            </svg>
                        </div>
                    </div>
                </Container>
            </div>

        </>
    )
}

export default HomeChooseRight
