import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import apiClient from '../axios/request';
import AdminLoader from '../components/adminLoader';

const HomeChoose = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('user');
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [navigate]);

  const [chooseUsSections, setChooseUsSections] = useState([
    { id: null, title: '', description: '' },
    { id: null, title: '', description: '' },
    { id: null, title: '', description: '' },
  ]);

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchChooseUsSections();
  }, []);

  const fetchChooseUsSections = async () => {
    try {
      setIsLoading(true);
      const response = await apiClient.get('/api/choose-us');
      if (response.data.length > 0) {
        setChooseUsSections(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching sections:', error);
      setErrorMessage('Failed to load sections. Please try again.');
    }
  };

  const handleSectionChange = (index, field, value) => {
    const updatedSections = chooseUsSections.map((section, i) => {
      if (i === index) {
        return { ...section, [field]: value };
      }
      return section;
    });
    setChooseUsSections(updatedSections);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);

    try {
      await apiClient.post('/api/save-choose-us', { sections: chooseUsSections });
      setSuccessMessage('Sections saved successfully!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
      fetchChooseUsSections();
      setIsLoading(false);
    } catch (error) {
      console.error('Error saving sections:', error);
      setErrorMessage('Failed to save sections. Please try again.');
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.headerWrapper}>
        <div style={styles.header}>
          <div style={styles.breadcrumbs}>
            <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
            <span style={styles.breadcrumbSeparator}>/</span>
            <Link to="/admin/home" style={styles.breadcrumbLink}>Home Page</Link>
            <span style={styles.breadcrumbSeparator}>/</span>
            <span style={styles.currentPage}>Choose Us Section</span>
          </div>
          <div style={styles.headerRight}>
            <button style={styles.logoutButton} onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div style={styles.title}>
        <h1>Choose Us Section</h1>
      </div>
      <div style={styles.contentContainer}>
        {isLoading ? (
          <AdminLoader />
        ) : (
          <>
            <div style={styles.card}>
              <form id="chooseUsForm" onSubmit={handleSubmit}>
                {chooseUsSections.map((section, index) => (
                  <div key={index}>
                    <div style={styles.inputGroup}>
                      <label style={styles.label}>Title {index + 1}</label>
                      <input
                        type="text"
                        placeholder="Enter section title"
                        value={section.title}
                        onChange={(e) => handleSectionChange(index, 'title', e.target.value)}
                        style={styles.input}
                        required
                      />
                    </div>
                    <div style={styles.inputGroup}>
                      <label style={styles.label}>Description {index + 1}</label>
                      <textarea
                        placeholder="Enter section description"
                        value={section.description}
                        onChange={(e) => handleSectionChange(index, 'description', e.target.value)}
                        style={styles.textarea}
                        required
                      />
                    </div>
                  </div>
                ))}

                {successMessage && (
                  <div style={styles.successMessage}>{successMessage}</div>
                )}
                {errorMessage && (
                  <div style={styles.errorMessage}>{errorMessage}</div>
                )}

                <div style={styles.bottomActions}>
                  <button onClick={handleBack} style={styles.backButton}>
                    ← Back
                  </button>
                  <button type="submit" style={styles.submitButton}>
                    Save All
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};


// Inline styles
const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#343a40',
  },
  successMessage: {
    marginTop: '20px', // Added margin for spacing
    padding: '10px',
    backgroundColor: '#d4edda',
    color: '#155724',
    border: '1px solid #c3e6cb',
    borderRadius: '4px',
    textAlign: 'center',
  },
  form: {
    maxWidth: '600px',
    margin: '0 auto',
  },
  section: {
    backgroundColor: '#fff',
    padding: '20px',
    margin: '10px 0',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  sectionTitle: {
    color: '#495057',
    marginBottom: '10px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#666',
    fontWeight: '500',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    marginBottom: '10px',
  },
  textarea: {
    display: 'block',
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
    resize: 'vertical',
  },
  mainContainer: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
  headerWrapper: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '15px 20px',
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  breadcrumbLink: {
    color: '#666',
    textDecoration: 'none',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbSeparator: {
    color: '#999',
    fontSize: '14px',
  },
  currentPage: {
    color: '#333',
    fontWeight: '500',
    fontSize: '14px',
  },
  headerRight: {
    display: 'flex',
    gap: '10px',
  },
  contentContainer: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0 20px',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  bannerItem: {
    marginBottom: '30px',
    padding: '20px',
    borderRadius: '4px',
    backgroundColor: 'rgb(235 233 233)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  imagePreview: {
    marginBottom: '15px',
  },
  image: {
    maxWidth: '100px',
    height: 'auto',
    borderRadius: '4px',
  },
  bottomActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    paddingTop: '20px',
    borderTop: '1px solid #e0e0e0',
  },
  rightButtons: {
    display: 'flex',
    gap: '10px',
  },
  backButton: {
    padding: '10px 20px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  addButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
  submitButton: {
    backgroundColor: '#2196F3',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1976D2',
    },
  },
  removeButton: {
    backgroundColor: '#f44336',
    color: 'white',
    padding: '8px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
  },
  logoutButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  errorMessage: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#f2dede',
    color: '#a94442',
    borderRadius: '4px',
    textAlign: 'center',
  },
};

export default HomeChoose;
