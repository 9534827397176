import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import './App.css';
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthLayout from './Pages/AuthLayout'
import Home from './Pages/Home'
import Contact from './Pages/Contact';
import FAQ from './Pages/FAQ';
import OurProduct from './Pages/OurProduct';
import Investment from './Pages/Investment';
import Loan from './Pages/Loan/index';
import Admin from './admin/Admin';
import Login from './admin/Login';
import AdminHome from './admin/AdminHome';
import HomeBanner from './admin/HomeBanner';
import HomeOptions from './admin/HomeOptions';
import HomeServices from './admin/HomeServices';
import HomeChoose from './admin/HomeChoose';
import HomeTestimonials from './admin/HomeTestimonials';
import AdminInvestment from './admin/investmentPage';
import BannerSection from './admin/investmentPage/bannerSection';
import BenefitSection from './admin/investmentPage/benefitSection';
import RequirementSection from './admin/investmentPage/requirementsSection';
import ProductPage from './admin/productPage/index';
import ContactPage from './admin/contactUsPage/index';
import FAQPage from './admin/faqPage/index';
import PrivateRoute from './admin/PrivateRoute';
// import LoanPage from './admin/loanPage/index';
function App() {
    return (
        <>
            <BrowserRouter>
                <LocationEffect />
                <Routes>
                    <Route path="/" element={<AuthLayout />}>
                        <Route index element={<Home />} />
                        <Route path='/' element={<Home />} />
                        <Route path='/products' element={<OurProduct />} />
                        <Route path='/products/:index' element={<OurProduct />} />
                        <Route path='/investments' element={<Investment />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='/faq' element={<FAQ />} />
                        <Route path='/loan' element={<Loan />} />
                    </Route>
                    <Route exact path='/login' element={<Login />} />

                    <Route exact path='/dashboard' element={<PrivateRoute><Admin /></PrivateRoute>} />
                    <Route exact path='/admin/home' element={<PrivateRoute><AdminHome /></PrivateRoute>} />
                    <Route exact path='/admin/home/main-section' element={<PrivateRoute><HomeBanner /></PrivateRoute>} />
                    <Route exact path='/admin/home/choices-section' element={<PrivateRoute><HomeOptions /></PrivateRoute>} />
                    <Route exact path='/admin/home/services-section' element={<PrivateRoute><HomeServices /></PrivateRoute>} />
                    <Route exact path='/admin/home/choose-us-section' element={<PrivateRoute><HomeChoose /></PrivateRoute>} />
                    <Route exact path='/admin/home/testimonials-section' element={<PrivateRoute><HomeTestimonials /></PrivateRoute>} />
                    <Route exact path='/admin/investment' element={<PrivateRoute><AdminInvestment /></PrivateRoute>} />
                    <Route exact path='/admin/investment-section1' element={<PrivateRoute><BannerSection /></PrivateRoute>} />
                    <Route exact path='/admin/investment-section2' element={<PrivateRoute><BenefitSection /></PrivateRoute>} />
                    <Route exact path='/admin/investment-section3' element={<PrivateRoute><HomeChoose /></PrivateRoute>} />
                    <Route exact path='/admin/investment-section4' element={<PrivateRoute><RequirementSection /></PrivateRoute>} />
                    <Route exact path='/admin/product-sections' element={<PrivateRoute><ProductPage /></PrivateRoute>} />
                    <Route exact path='/admin/contact-us-sections' element={<PrivateRoute><ContactPage /></PrivateRoute>} />
                    <Route exact path='/admin/faq-sections' element={<PrivateRoute><FAQPage /></PrivateRoute>} />
                    {/* <Route exact path='/admin/loan' element={<PrivateRoute><LoanPage /></PrivateRoute>} /> */}
                </Routes>
            </BrowserRouter>
        </>
    );
}

const LocationEffect = () => {
    const location = useLocation();
    useEffect(() => {
        const isPageRefresh = location.pathname === '/loan';
        if (!isPageRefresh) {
            return () => {
                localStorage.removeItem('inputFile');
                localStorage.removeItem('formData');
                localStorage.removeItem('step');
            };
        }
    }, [location]);
    return null;
};

export default App;