import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const GeneralContactInfo = ({ chooseUsSections }) => {
    const formattedAddresses = chooseUsSections && chooseUsSections[1]?.addresses?.split('\n')?.map((line, index) => (
        <span key={index}>
            {line}
            <br />
        </span>
    ));
    function formatPhoneNumber(number) {
        const numStr = number.toString();
        const formatted = numStr.replace(/(\d{3})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');
        return formatted;
    }
    return (
        <>
            <div className='general_ct-info'>
                <Container>
                    <h2 data-aos="fade-up" data-aos-duration="{500}">{chooseUsSections[1].header_title}</h2>
                    <Row>
                        <Col lg={4} md={6} sm={12} data-aos="fade-up" data-aos-duration="{500}">
                            <div className='box'>
                                <div className='icon'>
                                    <svg
                                        width={34} height={34} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M28.1615 24.9889L21.32 23.6252C20.8046 23.5213 20.0814 23.7397 19.7098 24.11L18.4739 25.3499C17.9798 25.8413 17.1194 25.9146 16.5588 25.503C15.428 24.676 13.8778 23.5559 12.598 22.2761C11.3181 20.9962 10.2007 19.446 9.37232 18.3153C8.96079 17.7532 9.03537 16.8929 9.52814 16.4001L10.7654 15.1629C11.1383 14.79 11.3567 14.0695 11.2528 13.5527L9.88506 6.71254C9.75454 6.06661 9.11661 5.54321 8.45736 5.54321H5.44215C4.06772 5.54321 2.33903 7.65679 1.61453 8.63434C1.41875 8.89937 1.20034 9.38015 1.1444 9.70378C0.798133 11.7481 -0.465754 18.2473 8.07913 26.7922C16.624 35.3371 23.1246 34.0759 25.1689 33.7269C25.4925 33.6723 25.9733 33.4539 26.2383 33.2568C27.2159 32.531 29.3295 30.8036 29.3295 29.4279V26.4127C29.3308 25.7587 28.8074 25.1181 28.1615 24.9889Z"
                                            fill="#243D59"
                                        />
                                        <path
                                            d="M24.5363 18.338C24.5829 18.7016 24.8972 18.9812 25.2648 18.9586L26.5939 18.8774C26.9588 18.8521 27.2412 18.5378 27.1999 18.1728C26.5553 12.6805 22.195 8.32012 16.7026 7.67419C16.3377 7.63024 16.0207 7.91259 15.9981 8.27884L15.9155 9.60798C15.8915 9.97423 16.1725 10.2885 16.5374 10.3378C20.6914 10.8945 23.9809 14.1854 24.5363 18.338Z"
                                            fill="#243D59"
                                        />
                                        <path
                                            d="M16.7288 0.875423C16.3625 0.847455 16.0522 1.13379 16.0376 1.50137L15.983 2.83185C15.9683 3.19943 16.2546 3.50841 16.6209 3.53771C24.4013 4.1823 30.6888 10.4724 31.3347 18.2542C31.3653 18.6204 31.673 18.9068 32.0406 18.8921L33.3711 18.8375C33.7373 18.8215 34.0236 18.5126 33.997 18.1437C33.2965 8.98747 25.885 1.57862 16.7288 0.875423Z"
                                            fill="#243D59"
                                        />
                                    </svg>
                                </div>
                                <h3>{chooseUsSections && chooseUsSections[1].phone_title}</h3>
                                <div className='description'>
                                    {chooseUsSections && chooseUsSections[1].phone_number_1 &&
                                        <p>+{formatPhoneNumber(chooseUsSections[1].phone_number_1)}</p>
                                    }
                                    {chooseUsSections && chooseUsSections[1].phone_number_2 &&
                                        <p>+{formatPhoneNumber(chooseUsSections[1].phone_number_2)}</p>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} data-aos="fade-up" data-aos-duration="{600}">
                            <div className='box'>
                                <div className='icon'>
                                    <svg
                                        width={37} height={34} viewBox="0 0 37 34" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M32.167 33.569V17.7495L18.4262 6.55396L4.68945 17.7495V33.569H14.8075V23.4494H22.0489V33.569H32.167Z"
                                            fill="#243D59"
                                        />
                                        <path
                                            d="M18.4074 3.87696L24.6936 9.00018V9.06731H24.7705L34.2862 16.8242L36.2012 14.4749L29.7473 9.13209V0.424282H24.6933V5.08936L18.447 0L0 14.4296L1.86957 16.8204L18.4074 3.87696Z"
                                            fill="#243D59"
                                        />
                                    </svg>

                                </div>
                                <h3>{chooseUsSections && chooseUsSections[1].address_title}</h3>
                                <div className='description'>
                                    <p>{formattedAddresses || "N/A"}</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} data-aos="fade-up" data-aos-duration="{700}">
                            <div className='box'>
                                <div className='icon'>
                                    <svg
                                        width={33} height={24} viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.8636 10.1282L2.03902 0.348002C2.48688 0.120001 3.00545 0 3.53583 0H29.4644C29.9947 0 30.5133 0.120001 30.9612 0.348002L17.1483 10.1162C16.7594 10.3803 16.2407 10.3802 15.8636 10.1282ZM32.6464 2.0522C32.6464 2.0522 32.5521 2.1482 32.4932 2.1842L18.4801 12.0961C17.879 12.5161 17.1837 12.7201 16.5001 12.7201C15.8165 12.7201 15.1329 12.5161 14.5437 12.108L0.506786 2.1839C0.506786 2.1839 0.400714 2.0999 0.353571 2.0519C0.129643 2.51992 0 3.04792 0 3.59989V20.4C0 22.38 1.59109 24 3.53571 24H29.4643C31.4089 24 33 22.38 33 20.4V3.59989C33 3.04788 32.8704 2.52019 32.6464 2.0522Z"
                                            fill="#243D59"
                                        />
                                    </svg>
                                </div>
                                <h3>{chooseUsSections && chooseUsSections[1].mail_title}</h3>
                                <div className='description'>
                                    <a href={`mailto:${chooseUsSections && chooseUsSections[1].emails}`} title="Email">
                                        {chooseUsSections && chooseUsSections[1].emails}
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default GeneralContactInfo
