import React, { useState, useEffect } from 'react';
import apiClient from '../axios/request';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [authMessage, setAuthMessage] = useState('');
  const navigate = useNavigate()

  // Redirect if already logged in
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      navigate('/dashboard'); // Redirect to dashboard if user is logged in
    }
  }, [navigate]);

  const validateForm = () => {
    let formErrors = {};
    if (!username) formErrors.username = 'Username is required';
    if (!password) formErrors.password = 'Password is required';
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await apiClient.post('/login', { username, password });
        if (response.data.message === 'Login successful') {
          setAuthMessage('Login successful! Redirecting...');
          // Save authentication token or user data in localStorage or sessionStorage (as needed)
          localStorage.setItem('user', JSON.stringify(response.data.user));
          // Redirect to dashboard or another page
          window.location.href = '/dashboard';  // Example redirect
        }
      } catch (error) {
        if (error.response) {
          setAuthMessage(error.response.data.message || 'Invalid credentials');
        } else {
          setAuthMessage('An error occurred. Please try again later.');
        }
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={handleSubmit}>
        <h2>Login</h2>
        {authMessage && <p style={styles.authMessage}>{authMessage}</p>}
        <div style={styles.formGroup}>
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={styles.input}
          />
          {errors.username && <span style={styles.error}>{errors.username}</span>}
        </div>
        <div style={styles.formGroup}>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
          />
          {errors.password && <span style={styles.error}>{errors.password}</span>}
        </div>
        <button type="submit" style={styles.button}>Submit</button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5',
  },
  form: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    width: '300px',
  },
  formGroup: {
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginTop: '5px',
    marginBottom: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  authMessage: {
    color: 'green',
    fontSize: '14px',
    marginBottom: '10px',
  },
};

export default Login;
