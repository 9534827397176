import React, { forwardRef } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import config from '../../config/configuration_keys.json';

const SalaryAdvance = forwardRef((props, ref) => {
    const REACT_APP_API_URL = config.REACT_APP_API_URL;
    const formattedDescription = props.description.split('\n').map((line, index) => (
        <span key={index}>
            {line}
            <br />
        </span>
    ));

    return (
        <>
            <div ref={ref} className={`saley-advance ${props.classNameValue ? props.classNameValue : ""}`}>
                <Container>
                    <Row className='row-salery' >
                        <Col lg={6} md={6} sm={12}>
                            <div className='image-saley' data-aos="fade-up" data-aos-duration={500}>
                                <img
                                    src={`${REACT_APP_API_URL}/uploads/${props.img}`}
                                    alt="Loading..."
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className='content'>
                                <div className='icon' data-aos="fade-up" data-aos-duration={500}>
                                    {props.svgCode}
                                </div>
                                <h2 data-aos="fade-up" data-aos-duration={500}>{props.heading}</h2>
                                <p data-aos="fade-up" data-aos-duration={600}>{formattedDescription}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
})

export default SalaryAdvance