import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import apiClient from '../axios/request';
import config from '../config/configuration_keys.json';
import AdminLoader from '../components/adminLoader';

const HomeOptions = () => {
  const navigate = useNavigate();
  const REACT_APP_API_URL = config.REACT_APP_API_URL;
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('user');
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [navigate]);

  const [options, setOptions] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Fetch existing options
  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      setIsLoading(true);
      const response = await apiClient.get('/api/options');
      setOptions(response.data.map(option => ({
        ...option,
        newIcon: null
      })));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching options:', error);
      setErrorMessage('Failed to load options. Please try again.');
    }
  };

  // Handle option input changes
  const handleOptionChange = (index, field, value) => {
    const updatedOptions = options.map((option, i) => {
      if (i === index) {
        return {
          ...option,
          [field]: field === 'newIcon' ? value : value,
        };
      }
      return option;
    });
    setOptions(updatedOptions);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  const handleBack = () => {
    navigate(-1);
  };

  // Add a new option
  const addOption = () => {
    setOptions([...options, {
      id: null,
      title: '',
      description: '',
      icon: null,
      newIcon: null
    }]);
  };

  // Remove an option
  const removeOption = async (id, index) => {
    try {
      if (id) {
        await apiClient.delete(`/api/options/${id}`);
      }
      setOptions(options.filter((_, i) => i !== index));
      setSuccessMessage('Option removed successfully!');
    } catch (error) {
      console.error('Error removing option:', error);
      setErrorMessage('Failed to remove option. Please try again.');
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);

    const formData = new FormData();

    // Append data for each option
    options.forEach((option, index) => {
      if (option.newIcon) {
        formData.append(`icon_${index}`, option.newIcon);
      }
      formData.append(`title_${index}`, option.title);
      formData.append(`description_${index}`, option.description);
      if (option.id) {
        formData.append(`id_${index}`, option.id);
      }
    });

    try {
      await apiClient.post(
        '/api/save-options',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Refresh the options data
      const refreshResponse = await apiClient.get('/api/options');
      setOptions(refreshResponse.data.map(option => ({
        ...option,
        newIcon: null
      })));

      setSuccessMessage('Options saved successfully!');
      setIsLoading(false);

      // Clear success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    } catch (error) {
      console.error('Error saving options:', error);
      setErrorMessage('Failed to save options. Please try again.');
    }
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.headerWrapper}>
        <div style={styles.header}>
          <div style={styles.breadcrumbs}>
            <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
            <span style={styles.breadcrumbSeparator}>/</span>
            <Link to="/admin/home" style={styles.breadcrumbLink}>Home Page</Link>
            <span style={styles.breadcrumbSeparator}>/</span>
            <span style={styles.currentPage}>Choices Section</span>
          </div>
          <div style={styles.headerRight}>
            <button style={styles.logoutButton} onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div style={styles.title}>
        <h1>Choices Section</h1>
      </div>
      <div style={styles.container}>
        {isLoading ? (
          <AdminLoader />
        ) : (
          <>
            <div style={styles.card}>
              <form onSubmit={handleSubmit}>
                {options.map((option, index) => (
                  <div key={index} style={styles.optionItem}>
                    {/* Display existing icon */}
                    {option.icon && (
                      <div style={styles.iconPreview}>
                        <img
                          src={`${REACT_APP_API_URL}/uploads/${option.icon}`}
                          alt={`Option ${index + 1}`}
                          style={styles.image}
                        />
                      </div>
                    )}

                    <div style={styles.inputGroup}>
                      <label style={styles.label}>Option {index + 1} Title</label>
                      <input
                        type="text"
                        placeholder="Enter option title"
                        value={option.title}
                        onChange={(e) => handleOptionChange(index, 'title', e.target.value)}
                        style={styles.input}
                        required
                      />
                    </div>

                    <div style={styles.inputGroup}>
                      <label style={styles.label}>Option {index + 1} Description</label>
                      <textarea
                        type="text"
                        placeholder="Enter option description"
                        value={option.description}
                        onChange={(e) => handleOptionChange(index, 'description', e.target.value)}
                        style={styles.input}
                        required
                      />
                    </div>

                    <div style={styles.inputGroup}>
                      <label style={styles.label}>Option {index + 1} Icon</label>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleOptionChange(index, 'newIcon', e.target.files[0])}
                        style={styles.input}
                      />
                    </div>

                    {options.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removeOption(option.id, index)}
                        style={styles.removeButton}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                {successMessage && (
                  <div style={styles.successMessage}>{successMessage}</div>
                )}
                {errorMessage && (
                  <div style={styles.errorMessage}>{errorMessage}</div>
                )}
                <div style={styles.bottomActions}>
                  <button onClick={handleBack} style={styles.backButton}>
                    ← Back
                  </button>
                  <div style={styles.rightButtons}>
                    <button type="button" onClick={addOption} style={styles.addButton}>
                      Add Option
                    </button>
                    <button type="submit" style={styles.submitButton}>
                      Save All
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  mainContainer: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
  title: {
    textAlign: 'center',
    // marginBottom: '10px',
    color: '#343a40',
  },
  headerWrapper: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '15px 20px',
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  breadcrumbLink: {
    color: '#666',
    textDecoration: 'none',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbSeparator: {
    color: '#999',
    fontSize: '14px',
  },
  container: {
    padding: '20px',
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
  },
  currentPage: {
    color: '#333',
    fontWeight: '500',
    fontSize: '14px',
  },
  headerRight: {
    display: 'flex',
    gap: '10px',
  },
  contentContainer: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0 20px',
  },
  card: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: '0 auto',
  },
  optionItem: {
    marginBottom: '30px',
    padding: '20px',
    borderRadius: '4px',
    backgroundColor: 'rgb(235 233 233)',
  },
  label: {
    fontSize: '1rem',
    color: '#555',
    marginBottom: '5px',
    display: 'block',
  },
  input: {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  addButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px'
  },
  backButton: {
    padding: '10px 20px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  removeButton: {
    backgroundColor: '#dc3545',
    color: '#fff',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  submitButton: {
    backgroundColor: '#28a745',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  bottomActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    paddingTop: '20px',
    borderTop: '1px solid #e0e0e0',
  },
  image: {
    width: '100px', // Adjust width as needed
    height: 'auto',
    marginBottom: '10px',
  },
  rightButtons: {
    display: 'flex',
    gap: '10px',
  },
  // ... (keeping the existing styles)
  buttonGroup: {
    display: 'flex',
    gap: '10px',
    marginTop: '20px',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  iconPreview: {
    marginBottom: '15px',
  },
  logoutButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  successMessage: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#dff0d8',
    color: '#3c763d',
    borderRadius: '4px',
    textAlign: 'center',
  },
  errorMessage: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#f2dede',
    color: '#a94442',
    borderRadius: '4px',
    textAlign: 'center',
  },
  // ... (rest of the existing styles)
};

export default HomeOptions;