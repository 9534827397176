import React from 'react';

const ReferenceIcon = (props) => (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M17.0858 20.0857C12.9278 20.0857 9.54297 16.7009 9.54297 12.5429C9.54297 8.38486 12.9278 5 17.0858 5C21.2438 5 24.6287 8.38486 24.6287 12.5429C24.6287 16.7009 21.2438 20.0857 17.0858 20.0857ZM17.0858 7.82857C14.4883 7.82857 12.3715 9.94529 12.3715 12.5429C12.3715 15.1404 14.4883 17.2571 17.0858 17.2571C19.6834 17.2571 21.8001 15.1404 21.8001 12.5429C21.8001 9.94529 19.6834 7.82857 17.0858 7.82857Z" fill="white" />
            <path d="M26.5426 37.9998H7.62886C4.52686 37.9998 2 35.4729 2 32.3709C2 26.6383 6.66714 21.9712 12.3997 21.9712H21.7717C27.5043 21.9712 32.1714 26.6383 32.1714 32.3709C32.1714 35.4729 29.6446 37.9998 26.5426 37.9998ZM12.3997 24.7998C8.22757 24.7998 4.82857 28.1988 4.82857 32.3709C4.82857 33.9125 6.08257 35.1712 7.62886 35.1712H26.5426C28.0841 35.1712 29.3429 33.9172 29.3429 32.3709C29.3429 28.1988 25.9439 24.7998 21.7717 24.7998H12.3997Z" fill="white" />
            <path d="M31.3896 20.8825C28.5704 20.8825 26.2793 18.5914 26.2793 15.7722C26.2793 12.9531 28.5704 10.6572 31.3896 10.6572C34.2087 10.6572 36.4999 12.9484 36.4999 15.7675C36.4999 18.5867 34.2087 20.8825 31.3896 20.8825ZM31.3896 13.4858C30.1309 13.4858 29.1079 14.5088 29.1079 15.7675C29.1079 17.0262 30.1309 18.0492 31.3896 18.0492C32.6483 18.0492 33.6713 17.0262 33.6713 15.7675C33.6713 14.5088 32.6483 13.4858 31.3896 13.4858Z" fill="white" />
            <path d="M37.4797 33.7571H35.198C34.4154 33.7571 33.7837 33.1254 33.7837 32.3428C33.7837 31.5602 34.4154 30.9285 35.198 30.9285H37.4797C38.3707 30.9285 39.092 30.2025 39.092 29.3162C39.092 26.5348 36.8291 24.2719 34.0477 24.2719H31.0871C30.3046 24.2719 29.6729 23.6402 29.6729 22.8576C29.6729 22.0751 30.3046 21.4434 31.0871 21.4434H34.0524C38.3943 21.4434 41.9253 24.9744 41.9253 29.3162C41.9253 31.7629 39.9311 33.7571 37.4797 33.7571Z" fill="white" />
        </g>
    </svg>
);

export default ReferenceIcon;