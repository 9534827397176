import React, { useEffect, useCallback, useState } from 'react'
import PageBanner from '../My-comonent/InvestmentComponent/PageBanner'
import BoxesHere from '../My-comonent/InvestmentComponent/SomeOfBenifits'
import Requirements from '../My-comonent/InvestmentComponent/Requirements'
import OneShortTearm from '../My-comonent/OneShortTearm'
import WhyChooseUs from '../My-comonent/WhyChooseUs'
import config from '../config/configuration_keys.json';
import Loader from '../components/loader'
import axios from 'axios';

const Investment = () => {
  const REACT_APP_API_URL = config.REACT_APP_API_URL;
  const [whyLoading, setWhyLoading] = useState(true);
  const [whyBanners, setWhyBanners] = useState([]);
  const [investLoading, setInvestLoading] = useState(true);
  const [investBanners, setInvestBanners] = useState([]);
  const [mainTitle, setMainTitle] = useState('');
  const [resourceGroup1, setResourceGroup1] = useState([
    {
      title: '',
      description: ''
    }
  ]);
  const [resourceGroup2, setResourceGroup2] = useState([
    {
      title: '',
      description: ''
    }
  ]);
  const [resourceGroup3, setResourceGroup3] = useState([
    {
      title: '',
      description: ''
    }
  ]);
  const [resourceGroup4, setResourceGroup4] = useState([
    {
      title: '',
      description: ''
    }
  ]);
  const [resourceGroup5, setResourceGroup5] = useState([
    {
      title: '',
      description: ''
    }
  ]);
  const [boxLoading, setBoxLoading] = useState(true);
  const [dataSections, setDataSections] = useState([
    {
      id: null,
      title: '',
      description: '',
      section_name: 'Requirements',
      point1: '',
      point2: '',
      point3: '',
      point4: '',
      image: '',
      tempImageUrl: '',
      title2: '',
      editor_content: ''
    },
  ]);

  const fetchWhyBanners = useCallback(async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/api/choose-us`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setWhyBanners(data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setWhyLoading(false);
    }
  }, [REACT_APP_API_URL]); // Memoized version of the function

  const fetchInvestBanners = useCallback(async () => {
    try {
      const response = await fetch(`${REACT_APP_API_URL}/api/investment-banner`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setInvestBanners(data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setInvestLoading(false);
    }
  }, [REACT_APP_API_URL]); // Memoized version of the function

  const fetchInvestDataSections = useCallback(async () => {
    try {
      const res = await axios.get(`${REACT_APP_API_URL}/admin/investment/getInvestmentSections`);
      if (res.data && res.data.length > 0) {
        const formattedData = res.data.reduce((acc, section) => {
          if (section.section_name === 'Main') {
            setMainTitle(section.title);
          }
          // For 'Resource Group' sections, add them to resources array
          if (section.section_name === 'Resource Group') {
            acc[1].resources.push({
              title: section.title,
              description: section.description,
            });
          }

          return acc;
        }, [
          { id: null, title: '', section_name: 'Main' },
          { id: null, section_name: 'Resource Group', resources: [] }
        ]);
        setResourceGroup1(formattedData[1].resources[0]);
        setResourceGroup2(formattedData[1].resources[1]);
        setResourceGroup3(formattedData[1].resources[2]);
        setResourceGroup4(formattedData[1].resources[3]);
        setResourceGroup5(formattedData[1].resources[4]);

        const requirementsSection = res.data.filter(
          (section) => section.section_name === "Requirements"
        );
        const updatedSections = requirementsSection.map((section) => {
          // Parse the points string into an array
          const parsedPoints = section.points ? JSON.parse(section.points) : [];

          // Assign each point to a separate field
          return {
            ...section,
            point1: parsedPoints[0] || '',
            point2: parsedPoints[1] || '',
            point3: parsedPoints[2] || '',
            point4: parsedPoints[3] || ''
          };
        });

        setDataSections(updatedSections);
      } else {
        setMainTitle('');
        setResourceGroup1([]);
        setResourceGroup2([]);
        setResourceGroup3([]);
        setResourceGroup4([]);
        setResourceGroup5([]);
        setDataSections([{
          id: null,
          title: '',
          description: '',
          section_name: 'Requirements',
          point1: '',
          point2: '',
          point3: '',
          point4: '',
          image: '',
          tempImageUrl: '',
          title2: '',
          editor_content: ''
        }]);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setBoxLoading(false);
    }
  }, [REACT_APP_API_URL]); // Memoized version of the function

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      await fetchWhyBanners();
      await fetchInvestDataSections();
      await fetchInvestBanners();
    };

    fetchData(); // Call the async function
  }, [fetchWhyBanners, fetchInvestDataSections, fetchInvestBanners]); // Add functions as dependencies


  const allLoaded = !whyLoading && !investLoading && !boxLoading

  return (
    <>
      {!allLoaded ? (
        <Loader />
      ) : (
        <>
          <PageBanner investBanners={investBanners} />
          <BoxesHere
            mainTitle={mainTitle}
            resourceGroup1={resourceGroup1}
            resourceGroup2={resourceGroup2}
            resourceGroup3={resourceGroup3}
            resourceGroup4={resourceGroup4}
            resourceGroup5={resourceGroup5}
          />
          <WhyChooseUs whyBanners={whyBanners} />
          <Requirements dataSections={dataSections} />
        </>
      )}
      <OneShortTearm />
    </>
  )
}

export default Investment
