import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import apiClient from '../axios/request';
import AdminLoader from '../components/adminLoader';

const HomeServices = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('user');
    if (!isAuthenticated) {
      navigate('/login'); // Redirect to login if not logged in
    }
  }, [navigate]);

  const [services, setServices] = useState([
    { id: null, title: '', description: '' },
    { id: null, title: '', description: '' },
    { id: null, title: '', description: '' },
    { id: null, title: '', description: '' },
  ]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      setIsLoading(true);
      const response = await apiClient.get('/api/services');
      const servicesData = response.data.length >= 4
        ? response.data.slice(0, 4)
        : [
          ...response.data,
          ...new Array(4 - response.data.length).fill({ id: null, title: '', description: '' })
        ];
      setServices(servicesData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching services:', error);
      setErrorMessage('Failed to load services. Please try again.');
    }
  };

  // Handle input changes
  const handleInputChange = (index, field, value) => {
    const updatedServices = services.map((service, i) => {
      if (i === index) {
        return { ...service, [field]: value };
      }
      return service;
    });
    setServices(updatedServices);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);
    const serviceData = services.map(service => ({
      id: service.id,
      title: service.title,
      description: service.description,
    }));

    try {
      await apiClient.post('/api/save-services', serviceData);
      setSuccessMessage('Services saved successfully!');

      // Clear success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);

      fetchServices();
      setIsLoading(false);
    } catch (error) {
      console.error('Error saving services:', error);
      setErrorMessage('Failed to save services. Please try again.');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.headerWrapper}>
        <div style={styles.header}>
          <div style={styles.breadcrumbs}>
            <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
            <span style={styles.breadcrumbSeparator}>/</span>
            <Link to="/admin/home" style={styles.breadcrumbLink}>Home Page</Link>
            <span style={styles.breadcrumbSeparator}>/</span>
            <span style={styles.currentPage}>Services Section</span>
          </div>
          <div style={styles.headerRight}>
            <button style={styles.logoutButton} onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div style={styles.title}>
        <h1>Services Section</h1>
      </div>
      <div style={styles.container}>
        {isLoading ? (
          <AdminLoader />
        ) : (
          <>
            <div style={styles.card}>
              <form onSubmit={handleSubmit}>
                {services.map((service, index) => (
                  <div key={index} style={styles.serviceSection}>
                    <h2 style={styles.sectionTitle}>Service {index + 1}</h2>
                    <div style={styles.inputGroup}>
                      <label style={styles.label}>Title</label>
                      <input
                        type="text"
                        placeholder="Enter service title"
                        value={service.title}
                        onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                        style={styles.input}
                        required
                      />
                    </div>
                    <div style={styles.inputGroup}>
                      <label style={styles.label}>Description</label>
                      <textarea
                        placeholder="Enter service description"
                        value={service.description}
                        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                        style={styles.input}
                        required
                      />
                    </div>
                  </div>
                ))}
                <div style={styles.bottomActions}>
                  <button onClick={handleBack} style={styles.backButton}>
                    ← Back
                  </button>
                  <div style={styles.rightButtons}>
                    <button type="submit" style={styles.submitButton}>
                      Save Services Sections
                    </button>
                  </div>
                </div>

                {successMessage && (
                  <div style={styles.successMessage}>{successMessage}</div>
                )}
                {errorMessage && (
                  <div style={styles.errorMessage}>{errorMessage}</div>
                )}
              </form>
            </div>
          </>
        )}
      </div>
    </div >
  );
};

// Styles
const styles = {
  title: {
    textAlign: 'center',
    // marginBottom: '10px',
    color: '#343a40',
  },
  mainContainer: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
  headerWrapper: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '15px 20px',
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  breadcrumbLink: {
    color: '#666',
    textDecoration: 'none',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbSeparator: {
    color: '#999',
    fontSize: '14px',
  },
  container: {
    padding: '20px',
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
  },
  bottomActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    paddingTop: '20px',
    borderTop: '1px solid #e0e0e0',
  },
  currentPage: {
    color: '#333',
    fontWeight: '500',
    fontSize: '14px',
  },
  headerRight: {
    display: 'flex',
    gap: '10px',
  },
  logoutButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  card: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: '0 auto',
  },
  backButton: {
    padding: '10px 20px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  serviceSection: {
    marginBottom: '20px',
  },
  inputGroup: {
    marginBottom: '10px',
  },
  label: {
    fontSize: '1rem',
    color: '#555',
    marginBottom: '5px',
    display: 'block',
  },
  sectionTitle: {
    textAlign: 'center',
    color: '#495057',
    marginBottom: '20px',
  },
  input: {
    display: 'block',
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  buttonGroup: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    backgroundColor: '#28a745',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  successMessage: {
    marginTop: '20px',
    color: '#28a745',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  errorMessage: {
    marginTop: '20px',
    color: '#dc3545',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};

export default HomeServices;
