import React, { useState, useCallback, useEffect } from 'react'
import GeneralContactInfo from '../My-comonent/ContactPageComponent/GeneralContactInfo'
import ContactBanner from '../My-comonent/ContactPageComponent/ContactBanner'
import OneShortTearm from '../My-comonent/OneShortTearm'
import FormContact from '../My-comonent/ContactPageComponent/FormContact'
import axios from 'axios';
import config from '../config/configuration_keys.json';
import Loader from '../components/loader'

const ContactPage = () => {
  const [chooseUsSections, setChooseUsSections] = useState([
    { id: null, image: '', tittle: '', description: '', tempImageUrl: '', section_name: 'Main' },
    {
      id: null,
      header_title: '',
      phone_title: '',
      address_title: '',
      mail_title: '',
      section_name: 'Contact Information',
      phone_number_1: '',
      phone_number_2: '',
      emails: '',
      addresses: '',
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const REACT_APP_API_URL = config.REACT_APP_API_URL;

  const fetchChooseUsSections = useCallback(async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_URL}/admin/contactUs/getContactUsSections`);
      if (response.data.length > 0) {
        setChooseUsSections(response.data);
        setIsLoading(false);
      } else {
        setChooseUsSections([
          { id: null, image: '', tittle: '', description: '', tempImageUrl: '', section_name: 'Main' },
          {
            id: null,
            header_title: '',
            phone_title: '',
            address_title: '',
            mail_title: '',
            section_name: 'Contact Information',
            phone_number_1: '',
            phone_number_2: '',
            emails: '',
            addresses: '',
          },
        ]);
      }
    } catch (error) {
      console.error('Error fetching Choose Us sections:', error);
    }
  }, [REACT_APP_API_URL]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchChooseUsSections();
  }, [fetchChooseUsSections]);

  return (
    <div className='contact-page' >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ContactBanner dataSections={chooseUsSections} />
          <GeneralContactInfo chooseUsSections={chooseUsSections} />
          <FormContact />
        </>
      )}
      <OneShortTearm />
    </div>
  )
}

export default ContactPage
