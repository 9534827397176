import React from 'react'
import Fixed from "./icon/Fixed"
import PeepeDem from "./icon/PeepeDem"
import Target from "./icon/Target"
import Unique from "./icon/Unique"
import Voluntary from "./icon/Voluntary"

function BoxesHere({ mainTitle, resourceGroup1, resourceGroup2, resourceGroup3, resourceGroup4, resourceGroup5 }) {
  return (
    <div className="invest-benefits">
      <div className="container">
        <h1>{mainTitle}</h1>
        <div className="row">
          {resourceGroup1 &&
            <div className="col">
              <div className="benefits-box one">
                <div className="inner-service-ct">
                  <div className='content'>
                    <div className="icon">
                      <Fixed />
                    </div>
                    <h2>{resourceGroup1?.title}</h2>
                    <p>{resourceGroup1?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          }
          {resourceGroup2 &&
            <div className="col">
              <div className="benefits-box two">
                <div className="inner-service-ct">
                  <div className='content'>
                    <div className="icon">
                      <Voluntary />
                    </div>
                    <h2>{resourceGroup2?.title}</h2>
                    <p>{resourceGroup2?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          }
          {resourceGroup3 &&
            <div className="col">
              <div className="benefits-box three">
                <div className="inner-service-ct">
                  <div className='content'>
                    <div className="icon">
                      <Unique />
                    </div>
                    <h2>{resourceGroup3?.title}</h2>
                    <p>{resourceGroup3?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          }
          {resourceGroup4 &&
            <div className="col">
              <div className="benefits-box four">
                <div className="inner-service-ct">
                  <div className='content'>
                    <div className="icon">
                      <Target />
                    </div>
                    <h2>{resourceGroup4?.title}</h2>
                    <p>{resourceGroup4?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          }
          {resourceGroup5 &&
            <div className="col">
              <div className="benefits-box five">
                <div className="inner-service-ct">
                  <div className='content'>
                    <div className="icon">
                      <PeepeDem />
                    </div>
                    <h2>{resourceGroup5?.title}</h2>
                    <p>{resourceGroup5?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default BoxesHere
