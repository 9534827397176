import React from 'react';

const PersonalIcon = (props) => (
    <svg
        width={32}
        height={36}
        viewBox="0 0 32 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.2447 9.2C25.2447 14.2812 21.1259 18.4 16.0447 18.4C10.9635 18.4 6.84473 14.2812 6.84473 9.2C6.84473 4.1188 10.9635 0 16.0447 0C21.1259 0 25.2447 4.1188 25.2447 9.2ZM22.4447 9.2C22.4447 12.7344 19.5791 15.6 16.0447 15.6C12.5104 15.6 9.64473 12.7344 9.64473 9.2C9.64473 5.66564 12.5104 2.8 16.0447 2.8C19.5791 2.8 22.4447 5.66564 22.4447 9.2Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.844727 34.183C0.844727 27.0126 6.65713 21.2002 13.8275 21.2002H18.2619C25.4323 21.2002 31.2447 27.0126 31.2447 34.183C31.2447 35.1861 30.4306 36.0002 29.4275 36.0002H2.66193C1.65881 36.0002 0.844727 35.1861 0.844727 34.183ZM18.2619 24.0002C23.5539 24.0002 27.9039 28.0378 28.3979 33.2002H3.69153C4.18529 28.0378 8.53513 24.0002 13.8275 24.0002H18.2619Z"
            fill="white"
        />
    </svg>
);

export default PersonalIcon;