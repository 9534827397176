import React, { useState, useEffect, useRef } from 'react'
import LoanBanner from '../../My-comonent/LoanPageComponent/index'
import OneShortTram from '../../My-comonent/OneShortTearm'
import PersonalContact from '../../My-comonent/LoanPageComponent/PersonalContact'
import axios from 'axios';
import UserIcon from './icon/personal';
import EmploymentIcon from './icon/employment';
import ReferenceIcon from './icon/reference_I';
import ReferenceIcon2 from './icon/reference_II';
import LoanDetailsIcon from './icon/loan_details';
import EmploymentContact from '../../My-comonent/LoanPageComponent/EmploymentContact';
import ReferenceIContact from '../../My-comonent/LoanPageComponent/ReferenceIContact';
import ReferenceIIContact from '../../My-comonent/LoanPageComponent/ReferenceIIContact';
import LoanContact from '../../My-comonent/LoanPageComponent/LoanContact';
import config from '../../config/configuration_keys.json';

const Contact = () => {
    const savedStep = localStorage.getItem('step');
    let step
    if (savedStep) {
        step = parseInt(savedStep)
    }
    const REACT_APP_API_URL = config.REACT_APP_API_URL;
    const [currentStep, setCurrentStep] = useState(step || 1);
    const [inputData, setInputData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [inputFile, setInputFile] = useState(() => {
        const savedData = localStorage.getItem('inputFile');
        return savedData ? JSON.parse(savedData) : {}; // If data exists in localStorage, use it; otherwise, use an empty object.
    });
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [tittle, setTittle] = useState();

    const refs = {
        surname: useRef(null),
        first_name: useRef(null),
        middle_name: useRef(null),
        salaryStatement: useRef(null),
        passportPhoto: useRef(null),
        governmentId: useRef(null),
        address: useRef(null),
        city: useRef(null),
        state: useRef(null),
        phone: useRef(null),
        government_id: useRef(null),
        kin: useRef(null),
        dob: useRef(null),
        kin_phone: useRef(null),
        employer_name: useRef(null),
        employment_dob: useRef(null),
        department_name: useRef(null),
        officialId: useRef(null),
        employer_phone: useRef(null),
        direct_line: useRef(null),
        account_name: useRef(null),
        account_number: useRef(null),
        account_opened: useRef(null),
        salary_amount: useRef(null),
        pay_date: useRef(null),
        company_position: useRef(null),
        degree: useRef(null),
        employer_address: useRef(null),
        employer_city: useRef(null),
        employer_state: useRef(null),
        confirmed_radio: useRef(null)
    };

    useEffect(() => {
        localStorage.setItem('inputFile', JSON.stringify(inputFile));
    }, [inputFile]);

    const steps = [
        { id: 1, label: 'Personal', icon: <UserIcon /> },
        { id: 2, label: 'Employment', icon: <EmploymentIcon /> },
        { id: 3, label: 'Reference I', icon: <ReferenceIcon /> },
        { id: 4, label: 'Reference II', icon: <ReferenceIcon2 /> },
        { id: 5, label: 'Loan Details', icon: <LoanDetailsIcon /> }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const fileName = event.target.name;
        const MAX_FILE_SIZE = 1 * 1024 * 1024;

        if (file && file.size > MAX_FILE_SIZE) {
            // If file is larger than 1MB, set error message and stop the process
            setErrorMessage(prevError => ({
                ...prevError,
                [fileName]: "Image size exceeds the 1MB limit. Please upload a smaller image."
            }));

            setInputData(prevData => ({
                ...prevData,
                [fileName]: ''
            }));

            return; // Stop processing further if the file is too large
        }

        setInputData(prevData => ({
            ...prevData,
            [fileName]: file
        }));

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64File = reader.result;
            setInputFile(prevData => {
                const updatedData = {
                    ...prevData,
                    [fileName]: base64File
                };
                // Save updated data to localStorage immediately
                localStorage.setItem('inputFile', JSON.stringify(updatedData));
                return updatedData;
            });
        };
        if (file) {
            reader.readAsDataURL(file); // Convert file to base64 string
        }
        setErrorMessage({ ...errorMessage, [fileName]: null });
    };

    const validateInputData = (inputData, step) => {
        const errors = {};
        if (step === 1) {
            // Personal Information Validations
            if (!inputData.surname) errors.surname = 'This field is required';
            if (!inputData.first_name) errors.first_name = 'This field is required';
            if (!inputData.middle_name) errors.middle_name = 'This field is required';
            if (!inputData.salaryStatement) errors.salaryStatement = 'This field is required';
            if (!inputData.passportPhoto) errors.passportPhoto = 'This field is required';
            if (!inputData.governmentId) errors.governmentId = 'This field is required';
            if (!inputData.address) errors.address = 'This field is required';
            if (!inputData.city) errors.city = 'This field is required';
            if (!inputData.state) errors.state = 'This field is required';
            if (!inputData.phone) errors.phone = 'This field is required';
            if (!inputData.government_id) errors.government_id = 'This field is required';
            if (!inputData.kin) errors.kin = 'This field is required';
            if (!inputData.dob) errors.dob = 'This field is required';
            if (!inputData.kin_phone) errors.kin_phone = 'This field is required';
        }

        if (step === 2) {
            // Employment Information Validations
            if (!inputData.employer_name) errors.employer_name = 'This field is required';
            if (!inputData.employment_dob) errors.employment_dob = 'This field is required';
            if (!inputData.department_name) errors.department_name = 'This field is required';
            if (!inputData.officialId) errors.officialId = 'This field is required';
            if (!inputData.employer_phone) errors.employer_phone = 'This field is required';
            if (!inputData.direct_line) errors.direct_line = 'This field is required';
            if (!inputData.account_name) errors.account_name = 'This field is required';
            if (!inputData.account_number) errors.account_number = 'This field is required';
            if (!inputData.account_opened) errors.account_opened = 'This field is required';
            if (!inputData.salary_amount) errors.salary_amount = 'This field is required';
            if (!inputData.confirmed_radio) errors.confirmed_radio = 'This field is required';
            if (!inputData.pay_date) errors.pay_date = 'This field is required';
            if (!inputData.company_position) errors.company_position = 'This field is required';
            if (!inputData.degree) errors.degree = 'This field is required';
            if (!inputData.employer_address) errors.employer_address = 'This field is required';
            if (!inputData.employer_city) errors.employer_city = 'This field is required';
            if (!inputData.employer_state) errors.employer_state = 'This field is required';
        }

        if (step === 5) {
            // Employment Information Validations
            if (!inputData.loan_amount) errors.loan_amount = 'This field is required';
            if (!inputData.reason) errors.reason = 'This field is required';
            if (!inputData.duration) errors.duration = 'This field is required';
            if (!inputData.installment) errors.installment = 'This field is required';
        }

        return errors;
    };

    function getFileMetadata(file) {
        return {
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            webkitRelativePath: file.webkitRelativePath,
        };
    }

    const handleSubmit = (step) => {
        setSubmitted(true);
        // Get the errors by calling validateInputData function
        const errors = validateInputData(inputData, step);

        // Set errors in state
        setErrorMessage(errors);
        if (Object.keys(errors).length === 0) {
            const dataToStore = { ...inputData };

            // Ensure files are stored with their metadata
            if (dataToStore.governmentId instanceof File) {
                dataToStore.governmentId = getFileMetadata(dataToStore.governmentId);
            }

            if (dataToStore.passportPhoto instanceof File) {
                dataToStore.passportPhoto = getFileMetadata(dataToStore.passportPhoto);
            }

            if (dataToStore.salaryStatement instanceof File) {
                dataToStore.salaryStatement = getFileMetadata(dataToStore.salaryStatement);
            }

            if (dataToStore.officialId instanceof File) {
                dataToStore.officialId = getFileMetadata(dataToStore.officialId);
            }

            // Save to localStorage (if necessary)
            localStorage.setItem('formData', JSON.stringify(dataToStore));
            setSubmitted(false);
            handleNext()
        } else {
            const firstErrorField = Object.keys(errors).find(key => errors[key]);
            if (firstErrorField && refs[firstErrorField] && refs[firstErrorField].current) {
                refs[firstErrorField].current.focus();

                if (firstErrorField === 'city' || firstErrorField === 'state') {
                    refs[firstErrorField].current.firstChild.focus();
                }

                if (firstErrorField === 'company_position' || firstErrorField === 'degree' ||
                    firstErrorField === 'employer_city' || firstErrorField === 'employer_state') {
                    const selectElement = refs[firstErrorField].current;
                    if (selectElement && selectElement.firstChild) {
                        selectElement.firstChild.focus();
                    }
                }
            }
        }
    };

    const handleFinalSubmit = async (step) => {
        const errors = validateInputData(inputData, step);
        setErrorMessage(errors);
        if (Object.keys(errors).length === 0) {
            setSubmitted(true);
            // Parse the saved data from localStorage
            const parsedData = JSON.parse(localStorage.getItem('formData'));

            // Prepare FormData to send
            const formData = new FormData();

            // Append all non-file fields
            Object.keys(parsedData).forEach(field => {
                if (field !== 'governmentId' && field !== 'passportPhoto' && field !== 'salaryStatement' && field !== 'officialId') {
                    formData.append(field, parsedData[field]);
                }
            });

            // Append actual files
            formData.append("salaryStatement", inputFile.salaryStatement);
            formData.append("governmentId", inputFile.governmentId);
            formData.append("officialId", inputFile.officialId);
            formData.append("passportPhoto", inputFile.passportPhoto);

            try {
                const response = await axios.post(`${REACT_APP_API_URL}/admin/loan/addLoanData`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                localStorage.removeItem('formData');
                localStorage.removeItem('inputFile');
                localStorage.removeItem('step');
                setShowModal(true);
                setSubmitted(false);
                setSuccessMessage(response.data.message)
                setTittle('Success')
            } catch (error) {
                setShowModal(true);
                setSubmitted(false)
                setSuccessMessage('Something Went Wrong')
                setTittle('Error')
            }
        }
    };

    const handleNext = () => {
        if (currentStep < steps.length) {
            setCurrentStep(prev => prev + 1);
            let nextStep = currentStep + 1
            localStorage.setItem('step', JSON.stringify(nextStep));
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep(prev => prev - 1);
            let nextStep = currentStep - 1
            localStorage.setItem('step', JSON.stringify(nextStep));
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setInputData({})
        setErrorMessage({})
        setCurrentStep(1)
        setSubmitted(false);
    };

    return (
        <div className='contact-page' >
            <LoanBanner
                currentStep={currentStep}
                steps={steps}
                handleNext={handleNext}
                handleBack={handleBack}
            />
            {currentStep === 1 && (
                <PersonalContact
                    inputData={inputData}
                    setInputData={setInputData}
                    handleFileChange={handleFileChange}
                    handleSubmit={handleSubmit}
                    submitted={submitted}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    refs={refs}
                />
            )}
            {currentStep === 2 && (
                <EmploymentContact
                    inputData={inputData}
                    setInputData={setInputData}
                    handleFileChange={handleFileChange}
                    handleSubmit={handleSubmit}
                    submitted={submitted}
                    errorMessage={errorMessage}
                    handleBack={handleBack}
                    setErrorMessage={setErrorMessage}
                    refs={refs}
                />
            )}
            {currentStep === 3 && (
                <ReferenceIContact
                    inputData={inputData}
                    setInputData={setInputData}
                    handleSubmit={handleSubmit}
                    handleBack={handleBack}
                />
            )}
            {currentStep === 4 && (
                <ReferenceIIContact
                    inputData={inputData}
                    setInputData={setInputData}
                    handleSubmit={handleSubmit}
                    handleBack={handleBack}
                />
            )}
            {currentStep === 5 && (
                <LoanContact
                    inputData={inputData}
                    setInputData={setInputData}
                    handleFileChange={handleFileChange}
                    handleSubmit={handleFinalSubmit}
                    submitted={submitted}
                    errorMessage={errorMessage}
                    handleBack={handleBack}
                    handleCloseModal={handleCloseModal}
                    showModal={showModal}
                    successMessage={successMessage}
                    tittle={tittle}
                    setErrorMessage={setErrorMessage}
                />
            )}
            <OneShortTram />
        </div>
    )
}

export default Contact
