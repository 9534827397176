import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import apiClient from '../axios/request';
import config from '../config/configuration_keys.json';
import AdminLoader from '../components/adminLoader';

const HomeBanner = () => {
  const navigate = useNavigate();
  const REACT_APP_API_URL = config.REACT_APP_API_URL;

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('user');
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [navigate]);

  const [banners, setBanners] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    try {
      setIsLoading(true);
      const response = await apiClient.get('/api/banners');
      setBanners(response.data.map(banner => ({
        ...banner,
        newImage: null
      })));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching banners:', error);
      setErrorMessage('Failed to load banners. Please try again.');
    }
  };

  const handleBannerChange = (index, field, value) => {
    const updatedBanners = banners.map((banner, i) => {
      if (i === index) {
        return {
          ...banner,
          [field]: field === 'newImage' ? value : value,
        };
      }
      return banner;
    });
    setBanners(updatedBanners);
  };

  const addBanner = () => {
    setBanners([...banners, {
      id: null,
      image: null,
      newImage: null,
      text: ''
    }]);
  };

  const removeBanner = async (id, index) => {
    try {
      if (id) {
        await apiClient.delete(`/api/banners/${id}`);
      }
      setBanners(banners.filter((_, i) => i !== index));
      setSuccessMessage('Banner removed successfully!');
    } catch (error) {
      console.error('Error removing banner:', error);
      setErrorMessage('Failed to remove banner. Please try again.');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);

    const formData = new FormData();

    banners.forEach((banner, index) => {
      if (banner.newImage) {
        formData.append(`image_${index}`, banner.newImage);
      }
      formData.append(`text_${index}`, banner.text);
      if (banner.id) {
        formData.append(`id_${index}`, banner.id);
      }
    });

    try {
      const response = await apiClient.post(
        '/api/save-home',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const updatedBanners = response.data.banners.map(banner => ({
        ...banner,
        newImage: null
      }));
      setBanners(updatedBanners);
      setSuccessMessage('Banners saved successfully!');

      const refreshResponse = await apiClient.get('/api/banners');
      setBanners(refreshResponse.data.map(banner => ({
        ...banner,
        newImage: null
      })));
      setIsLoading(false);
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    } catch (error) {
      console.error('Error saving banners:', error);
      setErrorMessage('Failed to save banners. Please try again.');
    }
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.headerWrapper}>
        <div style={styles.header}>
          <div style={styles.breadcrumbs}>
            <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
            <span style={styles.breadcrumbSeparator}>/</span>
            <Link to="/admin/home" style={styles.breadcrumbLink}>Home Page</Link>
            <span style={styles.breadcrumbSeparator}>/</span>
            <span style={styles.currentPage}>Main Section</span>
          </div>
          <div style={styles.headerRight}>
            <button style={styles.logoutButton} onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div style={styles.title}>
        <h1>Main Section</h1>
      </div>
      <div style={styles.contentContainer}>
        {isLoading ? (
          <AdminLoader />
        ) : (
          <>
            <div style={styles.card}>
              <form id="bannerForm" onSubmit={handleSubmit}>
                {banners.map((banner, index) => (
                  <div key={index} style={styles.bannerItem}>
                    {banner.image && (
                      <div style={styles.imagePreview}>
                        <img
                          src={`${REACT_APP_API_URL}/uploads/${banner.image}`}
                          alt={`Banner ${index + 1}`}
                          style={styles.image}
                        />
                      </div>
                    )}

                    <div style={styles.inputGroup}>
                      <label style={styles.label}>Banner {index + 1} Image</label>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleBannerChange(index, 'newImage', e.target.files[0])}
                        style={styles.input}
                      />
                    </div>

                    <div style={styles.inputGroup}>
                      <label style={styles.label}>Banner {index + 1} Text</label>
                      <input
                        type="text"
                        placeholder="Enter banner text"
                        value={banner.text}
                        onChange={(e) => handleBannerChange(index, 'text', e.target.value)}
                        style={styles.input}
                        required
                      />
                    </div>

                    {banners.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removeBanner(banner.id, index)}
                        style={styles.removeButton}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}

                {successMessage && (
                  <div style={styles.successMessage}>{successMessage}</div>
                )}
                {errorMessage && (
                  <div style={styles.errorMessage}>{errorMessage}</div>
                )}

                <div style={styles.bottomActions}>
                  <button onClick={handleBack} style={styles.backButton}>
                    ← Back
                  </button>
                  <div style={styles.rightButtons}>
                    <button type="button" onClick={addBanner} style={styles.addButton}>
                      Add Banner
                    </button>
                    <button type="submit" style={styles.submitButton}>
                      Save All
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  mainContainer: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
  title: {
    textAlign: 'center',
    marginBottom: '10px',
    color: '#343a40',
  },
  headerWrapper: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '15px 20px',
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  breadcrumbLink: {
    color: '#666',
    textDecoration: 'none',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbSeparator: {
    color: '#999',
    fontSize: '14px',
  },
  currentPage: {
    color: '#333',
    fontWeight: '500',
    fontSize: '14px',
  },
  headerRight: {
    display: 'flex',
    gap: '10px',
  },
  contentContainer: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0 20px',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  bannerItem: {
    marginBottom: '30px',
    padding: '20px',
    borderRadius: '4px',
    backgroundColor: 'rgb(235 233 233)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#666',
    fontWeight: '500',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    marginBottom: '10px',
  },
  imagePreview: {
    marginBottom: '15px',
  },
  image: {
    maxWidth: '100px',
    height: 'auto',
    borderRadius: '4px',
  },
  bottomActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    paddingTop: '20px',
    borderTop: '1px solid #e0e0e0',
  },
  rightButtons: {
    display: 'flex',
    gap: '10px',
  },
  backButton: {
    padding: '10px 20px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  addButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
  submitButton: {
    backgroundColor: '#2196F3',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1976D2',
    },
  },
  removeButton: {
    backgroundColor: '#f44336',
    color: 'white',
    padding: '8px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
  },
  logoutButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  successMessage: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#dff0d8',
    color: '#3c763d',
    borderRadius: '4px',
    textAlign: 'center',
  },
  errorMessage: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#f2dede',
    color: '#a94442',
    borderRadius: '4px',
    textAlign: 'center',
  },
};

export default HomeBanner;