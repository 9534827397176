import React from 'react';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
  const navigate = useNavigate();

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('user'); // Remove authentication token
    navigate('/login'); // Redirect to login page
  };

  // Handle page navigation
  const handlePageClick = (page) => {
    navigate(`/${page.toLowerCase()}`); // Navigate to respective pages
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1>Admin Dashboard</h1>
        <button style={styles.logoutButton} onClick={handleLogout}>Logout</button>
      </div>
      <div style={styles.pageList}>
        <ul>
          <li onClick={() => handlePageClick('Admin/Home')} style={styles.listItem}>Home Page</li>
          <li onClick={() => handlePageClick('Admin/Product-Sections')} style={styles.listItem}>Product page</li>
          <li onClick={() => handlePageClick('Admin/investment')} style={styles.listItem}>Investment page</li>
          {/* <li onClick={() => handlePageClick('Admin/Loan')} style={styles.listItem}>Loan Page</li> */}
          <li onClick={() => handlePageClick('Admin/FAQ-Sections')} style={styles.listItem}>FAQ Page</li>
          <li onClick={() => handlePageClick('Admin/Contact-Us-Sections')} style={styles.listItem}>Contact Us Page</li>
        </ul>
      </div>
    </div>
  );
};

// Inline styles for the component
const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f8f9fa',
    height: '100vh',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  logoutButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  pageList: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    width: '60%',
    margin: '0 auto'
  },
  listItem: {
    cursor: 'pointer',
    margin: '10px 0',
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: '#f1f1f1',
    textAlign: 'center',
    fontWeight: 'bold',
  },
};

export default Admin;
