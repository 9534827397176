import React, { useEffect, useState, useRef } from 'react'
import { Navbar, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/MLL-logo.svg'
import Hamburger from 'hamburger-react'

export default function Header() {
  const [scrolltopdata, setScrollTopData] = useState('');
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 15) {
        setScrollTopData('');
      } else if (location.pathname === '/loan') {
        setScrollTopData('loan-fixed');
      } else {
        setScrollTopData('fixed');
      }
    };

    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsNavOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [location]);

  const handleNavBar = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLinkClick = () => {
    setIsNavOpen(false);
  };

  return (
    <React.Fragment>
      <header className={`header-wrapper ${scrolltopdata}`}>
        <Navbar expand="lg" className="navbar" ref={navRef}>
          <Container>
            <Link to="/">
              <img src={logo} alt="Company Logo" />
            </Link>

            <div className="d-block d-lg-none cross">
              <Hamburger
                toggled={isNavOpen}
                toggle={setIsNavOpen}
                onClick={handleNavBar}
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
                size={20}
                duration={0.3}
              />
            </div>
            <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    to="/"
                    className={location.pathname === '/loan' ? 'loan-nav-link' : 'nav-link'}
                    onClick={handleLinkClick}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/products"
                    className={location.pathname === '/loan' ? 'loan-nav-link' : 'nav-link'}
                    onClick={handleLinkClick}
                  >
                    Products
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/investments"
                    className={location.pathname === '/loan' ? 'loan-nav-link' : 'nav-link'}
                    onClick={handleLinkClick}
                  >
                    Investments
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/loan"
                    className={location.pathname === '/loan' ? 'loan-nav-link' : 'nav-link'}
                    onClick={handleLinkClick}
                  >
                    Loan Application
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contact"
                    className={location.pathname === '/loan' ? 'loan-nav-link' : 'nav-link'}
                    onClick={handleLinkClick}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
              {/* <form className="d-flex online_banking">
                <Link
                  to="/"
                  className='nav-link'
                  onClick={handleLinkClick}
                >
                  <button className="btn" type="submit">
                    Register
                  </button>
                </Link>
              </form> */}
            </div>
          </Container>
        </Navbar>
      </header>
    </React.Fragment >
  )
}
