import React, { useState, useCallback,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/configuration_keys.json';
import AdminLoader from '../../components/adminLoader';

const HomeChoose = () => {
    const navigate = useNavigate();
    const REACT_APP_API_URL = config.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const isAuthenticated = localStorage.getItem('user');
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [navigate]);

    const [chooseUsSections, setChooseUsSections] = useState([
        { id: null, image: '', tittle: '', description: '', tempImageUrl: '', section_name: 'Main' },
        {
            id: null,
            header_title: '',
            phone_title: '',
            address_title: '',
            mail_title: '',
            section_name: 'Contact Information',
            phone_number_1: '',
            phone_number_2: '',
            emails: '',
            addresses: '',
        },
    ]);
    const [successMessage, setSuccessMessage] = useState('');

    const fetchChooseUsSections = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${REACT_APP_API_URL}/admin/contactUs/getContactUsSections`);
            if (response.data.length > 0) {
                setChooseUsSections(response.data);
            } else {
                setChooseUsSections([
                    { id: null, image: '', tittle: '', description: '', tempImageUrl: '', section_name: 'Main' },
                    {
                        id: null,
                        header_title: '',
                        phone_title: '',
                        address_title: '',
                        mail_title: '',
                        section_name: 'Contact Information',
                        phone_number_1: '',
                        phone_number_2: '',
                        emails: '',
                        addresses: '',
                    },
                ]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching Choose Us sections:', error);
            setIsLoading(false);
        }
    }, [REACT_APP_API_URL]);

    useEffect(() => {
        fetchChooseUsSections();
    }, [fetchChooseUsSections]);

    const handleInputChange = (index, field, value) => {
        const updatedSections = chooseUsSections.map((section, i) => {
            if (i === index) {
                if (field === 'header_title') {
                    return {
                        ...section,
                        header_title: value
                    };
                } else if (field === 'phone_title') {
                    return {
                        ...section,
                        phone_title: value
                    };
                } else if (field === 'address_title') {
                    return {
                        ...section,
                        address_title: value
                    }
                } else if (field === 'phone_number_1') {
                    return {
                        ...section,
                        phone_number_1: value
                    };
                } else if (field === 'phone_number_2') {
                    return {
                        ...section,
                        phone_number_2: value
                    };
                } else if (field === 'emails') {
                    return {
                        ...section,
                        emails: value
                    };
                } else if (field === 'addresses') {
                    return {
                        ...section,
                        addresses: value
                    };
                } else {
                    return {
                        ...section,
                        [field]: value
                    };
                }
            }
            return section;
        });
        setChooseUsSections(updatedSections);
    };

    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            // Create a temporary URL for preview
            const tempUrl = URL.createObjectURL(file);
            const updatedSections = chooseUsSections.map((section, i) => {
                if (i === index) {
                    return {
                        ...section,
                        image: file,
                        tempImageUrl: tempUrl
                    };
                }
                return section;
            });
            setChooseUsSections(updatedSections);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = new FormData();

        const sectionsData = chooseUsSections.map(section => ({
            id: section.id,
            tittle: section.tittle,
            description: section.description,
            ...(section.section_name === 'Contact Information' && {
                phoneTittle: section.phone_title,
                addressTittle: section.address_title,
                mailTittle: section.mail_title,
                headerTittle: section.header_title,
                emails: section.emails,
                addresses: section.addresses,
                phoneNumbers1: section.phone_number_1,
                phoneNumbers2: section.phone_number_2,
            }),
            sectionName: section.section_name,
            image: typeof section.image === 'string' ? section.image : null // Keep existing image path if no new file
        }));

        formData.append('sections', JSON.stringify(sectionsData));

        // Append only the new files
        chooseUsSections?.forEach((section, index) => {
            if (section.image instanceof File) {
                formData.append(`sections[${index}][image]`, section.image);
            }
        });

        try {
            const url = `${REACT_APP_API_URL}/admin/contactUs/addOrUpdateContact`;
            let data = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            fetchChooseUsSections();
            setIsLoading(false);
            setSuccessMessage(data.data);
            setTimeout(() => setSuccessMessage(''), 5000);
        } catch (error) {
            console.error('Error saving Product:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/login');
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div style={styles.mainContainer}>
            <div style={styles.headerWrapper}>
                <div style={styles.header}>
                    <div style={styles.breadcrumbs}>
                        <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
                        <span style={styles.breadcrumbSeparator}>/</span>
                        <span style={styles.currentPage}>Contact Us Page</span>
                    </div>
                    <div style={styles.headerRight}>
                        <button style={styles.logoutButton} onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                </div>
            </div>

            <div style={styles.container}>
                {isLoading ? (
                    <AdminLoader />
                ) : (
                    <>
                        <h1 style={styles.title}>Contact Us Page</h1>
                        <form onSubmit={handleSubmit} style={styles.form}>
                            {chooseUsSections?.map((section, index) => (
                                <div key={index} style={styles.section}>
                                    <h2 style={styles.sectionTitle}>{section.section_name} Section</h2>
                                    {section.section_name === 'Main' && section.tempImageUrl ? (
                                        <img
                                            src={section.tempImageUrl}
                                            alt={`Banner ${index + 1}`}
                                            style={styles.image}
                                        />
                                    ) : section.image && (
                                        <img
                                            src={`${REACT_APP_API_URL}/uploads/${section.image}`}
                                            alt={`Banner ${index + 1}`}
                                            style={styles.image}
                                        />
                                    )}
                                    {section.section_name === 'Main' && (
                                        <>
                                            <label style={styles.label}>Main Banner Image</label>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleFileChange(index, e)}
                                                style={styles.input}
                                                required={!section.image}
                                            />
                                            <label style={styles.label}>Title</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Title"
                                                value={section.tittle}
                                                onChange={(e) => handleInputChange(index, 'tittle', e.target.value)}
                                                style={styles.input}
                                                required
                                            />
                                            <label style={styles.label}>Description</label>
                                            <textarea
                                                placeholder="Enter Description"
                                                value={section.description}
                                                onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                                style={styles.textarea}
                                                required
                                            />
                                        </>
                                    )}
                                    {section.section_name === 'Contact Information' && (
                                        <>
                                            <label style={styles.label}>Header Title</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Header Title"
                                                value={section.header_title}
                                                onChange={(e) => handleInputChange(index, 'header_title', e.target.value)}
                                                style={styles.input}
                                                required
                                            />
                                            <label style={styles.label}>Phone Title</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Phone Title"
                                                value={section.phone_title}
                                                onChange={(e) => handleInputChange(index, 'phone_title', e.target.value)}
                                                style={styles.input}
                                                required
                                            />
                                            <label style={styles.label}>Phone Number 1</label>
                                            <input
                                                type="number"
                                                placeholder="Enter Phone Number"
                                                value={section?.phone_number_1}
                                                onChange={(e) => handleInputChange(index, 'phone_number_1', e.target.value)}
                                                style={styles.input}
                                                required
                                                onWheel={(e) => {
                                                    e.preventDefault();
                                                    (e.target).blur();
                                                }}
                                            />
                                            <label style={styles.label}>Phone Number 2</label>
                                            <input
                                                type="number"
                                                placeholder="Enter Phone Number"
                                                value={section.phone_number_2}
                                                onChange={(e) => handleInputChange(index, 'phone_number_2', e.target.value)}
                                                style={styles.input}
                                                onWheel={(e) => {
                                                    e.preventDefault();
                                                    (e.target).blur();
                                                }}
                                            />
                                            <label style={styles.label}>Email Title</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Email Title"
                                                value={section.mail_title}
                                                onChange={(e) => handleInputChange(index, 'mail_title', e.target.value)}
                                                style={styles.input}
                                                required
                                            />
                                            <label style={styles.label}>Email</label>
                                            <input
                                                type="email"
                                                placeholder="Enter Email"
                                                value={section.emails}
                                                onChange={(e) => handleInputChange(index, 'emails', e.target.value)}
                                                style={styles.input}
                                                required
                                            />
                                            <label style={styles.label}>Address Title</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Address Title"
                                                value={section.address_title}
                                                onChange={(e) => handleInputChange(index, 'address_title', e.target.value)}
                                                style={styles.input}
                                                required
                                            />
                                            <label style={styles.label}>Address</label>
                                            <textarea
                                                type="text"
                                                placeholder="Enter Address"
                                                value={section.addresses}
                                                onChange={(e) => handleInputChange(index, 'addresses', e.target.value)}
                                                style={styles.textarea}
                                                required
                                            />
                                        </>
                                    )}
                                </div>
                            ))}
                            {successMessage && <div style={styles.successMessage}>{successMessage}</div>}
                            <div style={styles.bottomActions}>
                                <button onClick={handleBack} style={styles.backButton}>
                                    ← Back
                                </button>
                                <div style={styles.rightButtons}>
                                    <button type="submit" style={styles.submitButton}>
                                        Save Contact Us Sections
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

const styles = {
    mainContainer: {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
    },
    headerWrapper: {
        width: '100%',
        backgroundColor: '#fff',
        borderBottom: '1px solid #e0e0e0',
        marginBottom: '10px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '15px 20px',
    },
    breadcrumbs: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    breadcrumbLink: {
        color: '#666',
        textDecoration: 'none',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    breadcrumbSeparator: {
        color: '#999',
        fontSize: '14px',
    },
    currentPage: {
        color: '#333',
        fontWeight: '500',
        fontSize: '14px',
    },
    headerRight: {
        display: 'flex',
        gap: '10px',
    },
    image: {
        maxWidth: '100px',
        height: 'auto',
        borderRadius: '4px',
    },
    bottomActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        paddingTop: '20px',
        borderTop: '1px solid #e0e0e0',
    },
    rightButtons: {
        display: 'flex',
        gap: '10px',
    },
    backButton: {
        padding: '10px 20px',
        backgroundColor: '#f0f0f0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    submitButton: {
        backgroundColor: '#2196F3',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#1976D2',
        },
    },
    logoutButton: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    successMessage: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#d4edda',
        color: '#155724',
        border: '1px solid #c3e6cb',
        borderRadius: '4px',
        textAlign: 'center',
    },
    container: {
        backgroundColor: '#f8f9fa',
        minHeight: '100vh',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#343a40',
    },
    form: {
        padding: '6px',
        maxWidth: '600px',
        margin: '0 auto',
    },
    section: {
        backgroundColor: '#fff',
        padding: '20px',
        margin: '10px 0',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    sectionTitle: {
        textAlign: 'center',
        color: '#495057',
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
    },
    textarea: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
        resize: 'vertical',
    },
};

export default HomeChoose;