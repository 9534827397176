import React, { useEffect } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const EmploymentContact = ({
    inputData,
    setInputData,
    handleSubmit,
    handleBack
}) => {
    const handleInput = (event) => {
        const { name, value } = event.target;
        // Update only the specific field, preserving the rest of the data
        setInputData(prevState => ({
            ...prevState,
            [name]: value,  // Update only the field that was changed
        }));
    };

    useEffect(() => {
        const storedData = localStorage.getItem('formData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setInputData(parsedData);
        }
    }, [setInputData]);

    return (
        <>
            <div className='loan_contact'>
                <Container>
                    <div className='details'>Reference II Details</div>
                    <Form>
                        <Row>
                            <Col lg={4} md={4} sm={12}>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Surname
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Enter surname'
                                            name='referII_surname'
                                            value={inputData.referII_surname}
                                            onChange={handleInput}
                                        />
                                    </label>
                                </div>

                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Relationship
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Enter your relationship'
                                            name='referII_relationship'
                                            value={inputData.referII_relationship}
                                            onChange={handleInput}
                                        />
                                    </label>
                                </div>
                            </Col>

                            <Col lg={4} md={4} sm={12}>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            First Name
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Enter first name'
                                            name='referII_first_name'
                                            value={inputData.referII_first_name}
                                            onChange={handleInput}
                                        />
                                    </label>
                                </div>

                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Employer
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Enter employment'
                                            name='referII_employment_name'
                                            value={inputData.referII_employment_name}
                                            onChange={handleInput}
                                        />
                                    </label>
                                </div>
                            </Col>

                            <Col lg={4} md={4} sm={12}>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Middle Name
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Enter middle name'
                                            name='referII_middle_name'
                                            value={inputData.referII_middle_name}
                                            onChange={handleInput}
                                        />
                                    </label>
                                </div>

                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Phone
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Enter phone number'
                                            name='referII_phone_name'
                                            value={inputData.referII_phone_name}
                                            onChange={handleInput}
                                        />
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>

                <Container>
                    <Form>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className='input_box'>
                                    <label>
                                        <div className='loan_title'>
                                            Address
                                        </div>
                                        <input
                                            type='text'
                                            placeholder='Enter full address'
                                            name='referII_address_name'
                                            value={inputData.referII_address_name}
                                            onChange={handleInput}
                                        />
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>

                <Container>
                    <Form>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div>
                                    <button
                                        type='button'
                                        className='back_btn'
                                        onClick={handleBack}
                                    >
                                        Back
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => handleSubmit(4)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </>
    )
}

export default EmploymentContact
