import React from 'react'
import { Container } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

const HomeHaveTrusted = ({ homeHaveBanners }) => {
    return (
        <>
            <div className="home-people-have-trusted">
                <Container>
                    <h2 data-aos="fade-up" data-aos-duration={500}>
                        Over 10 thousand people have trusted
                        <br /> our services.
                    </h2>
                    <Swiper
                        spaceBetween={27}
                        loop={true}
                        slidesPerView={4}
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        breakpoints={{
                            320: { // mobile
                                slidesPerView: 1,
                            },
                            768: { // medium devices
                                slidesPerView: 2,
                            },
                            1200: { // desktop
                                slidesPerView: 4,
                            },
                        }}
                        navigation={{
                            nextEl: ".trust-next",
                            prevEl: ".trust-prev",
                        }}
                    >
                        {homeHaveBanners && homeHaveBanners.map((banner, index) => (
                            <SwiperSlide>
                                <div className="people_trusteded-review">
                                    <div className="star-with-text">
                                        <div className="rating">
                                            <svg
                                                width={21}
                                                height={20}
                                                viewBox="0 0 21 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.2207 5.79415L19.6776 6.44321C20.9414 6.62718 21.4478 8.19588 20.5347 9.09749L17.3138 12.2772C16.951 12.6354 16.7861 13.1508 16.8723 13.6556L17.6388 18.1394C17.856 19.4108 16.5364 20.3822 15.4053 19.7832L11.4173 17.6708C10.9681 17.433 10.4319 17.4334 9.98346 17.6724L6.00027 19.7947C4.87082 20.3964 3.54865 19.4281 3.76291 18.1562L4.51873 13.6706C4.60374 13.1655 4.43766 12.6503 4.07399 12.2932L0.846163 9.12113C-0.0692574 8.22162 0.43339 6.65176 1.69696 6.46474L6.15222 5.80515C6.65394 5.73096 7.08733 5.41193 7.31109 4.95223L9.29921 0.869459C9.86301 -0.288219 11.4958 -0.290325 12.0622 0.866182L14.0598 4.94403C14.2847 5.40303 14.7188 5.72112 15.2207 5.79415Z"
                                                    fill="#FFBA01"
                                                />
                                            </svg>
                                            <svg
                                                width={21}
                                                height={20}
                                                viewBox="0 0 21 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.2207 5.79415L19.6776 6.44321C20.9414 6.62718 21.4478 8.19588 20.5347 9.09749L17.3138 12.2772C16.951 12.6354 16.7861 13.1508 16.8723 13.6556L17.6388 18.1394C17.856 19.4108 16.5364 20.3822 15.4053 19.7832L11.4173 17.6708C10.9681 17.433 10.4319 17.4334 9.98346 17.6724L6.00027 19.7947C4.87082 20.3964 3.54865 19.4281 3.76291 18.1562L4.51873 13.6706C4.60374 13.1655 4.43766 12.6503 4.07399 12.2932L0.846163 9.12113C-0.0692574 8.22162 0.43339 6.65176 1.69696 6.46474L6.15222 5.80515C6.65394 5.73096 7.08733 5.41193 7.31109 4.95223L9.29921 0.869459C9.86301 -0.288219 11.4958 -0.290325 12.0622 0.866182L14.0598 4.94403C14.2847 5.40303 14.7188 5.72112 15.2207 5.79415Z"
                                                    fill="#FFBA01"
                                                />
                                            </svg>
                                            <svg
                                                width={21}
                                                height={20}
                                                viewBox="0 0 21 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.2207 5.79415L19.6776 6.44321C20.9414 6.62718 21.4478 8.19588 20.5347 9.09749L17.3138 12.2772C16.951 12.6354 16.7861 13.1508 16.8723 13.6556L17.6388 18.1394C17.856 19.4108 16.5364 20.3822 15.4053 19.7832L11.4173 17.6708C10.9681 17.433 10.4319 17.4334 9.98346 17.6724L6.00027 19.7947C4.87082 20.3964 3.54865 19.4281 3.76291 18.1562L4.51873 13.6706C4.60374 13.1655 4.43766 12.6503 4.07399 12.2932L0.846163 9.12113C-0.0692574 8.22162 0.43339 6.65176 1.69696 6.46474L6.15222 5.80515C6.65394 5.73096 7.08733 5.41193 7.31109 4.95223L9.29921 0.869459C9.86301 -0.288219 11.4958 -0.290325 12.0622 0.866182L14.0598 4.94403C14.2847 5.40303 14.7188 5.72112 15.2207 5.79415Z"
                                                    fill="#FFBA01"
                                                />
                                            </svg>
                                            <svg
                                                width={21}
                                                height={20}
                                                viewBox="0 0 21 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.2207 5.79415L19.6776 6.44321C20.9414 6.62718 21.4478 8.19588 20.5347 9.09749L17.3138 12.2772C16.951 12.6354 16.7861 13.1508 16.8723 13.6556L17.6388 18.1394C17.856 19.4108 16.5364 20.3822 15.4053 19.7832L11.4173 17.6708C10.9681 17.433 10.4319 17.4334 9.98346 17.6724L6.00027 19.7947C4.87082 20.3964 3.54865 19.4281 3.76291 18.1562L4.51873 13.6706C4.60374 13.1655 4.43766 12.6503 4.07399 12.2932L0.846163 9.12113C-0.0692574 8.22162 0.43339 6.65176 1.69696 6.46474L6.15222 5.80515C6.65394 5.73096 7.08733 5.41193 7.31109 4.95223L9.29921 0.869459C9.86301 -0.288219 11.4958 -0.290325 12.0622 0.866182L14.0598 4.94403C14.2847 5.40303 14.7188 5.72112 15.2207 5.79415Z"
                                                    fill="#FFBA01"
                                                />
                                            </svg>
                                            <svg
                                                width={21}
                                                height={20}
                                                viewBox="0 0 21 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.2207 5.79415L19.6776 6.44321C20.9414 6.62718 21.4478 8.19588 20.5347 9.09749L17.3138 12.2772C16.951 12.6354 16.7861 13.1508 16.8723 13.6556L17.6388 18.1394C17.856 19.4108 16.5364 20.3822 15.4053 19.7832L11.4173 17.6708C10.9681 17.433 10.4319 17.4334 9.98346 17.6724L6.00027 19.7947C4.87082 20.3964 3.54865 19.4281 3.76291 18.1562L4.51873 13.6706C4.60374 13.1655 4.43766 12.6503 4.07399 12.2932L0.846163 9.12113C-0.0692574 8.22162 0.43339 6.65176 1.69696 6.46474L6.15222 5.80515C6.65394 5.73096 7.08733 5.41193 7.31109 4.95223L9.29921 0.869459C9.86301 -0.288219 11.4958 -0.290325 12.0622 0.866182L14.0598 4.94403C14.2847 5.40303 14.7188 5.72112 15.2207 5.79415Z"
                                                    fill="#FFBA01"
                                                />
                                            </svg>
                                        </div>
                                        <p>{banner?.review}</p>
                                    </div>
                                    <div className="auther_name">
                                        <h3>{banner?.title}</h3>
                                        <h4>{banner?.designation}</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="people-review-nt_pt">
                        <div className="swiper-button-next trust-next">
                            <svg
                                width={13}
                                height={18}
                                viewBox="0 0 13 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.0625 0L0.250001 2.8125L6.4375 9L0.250001 15.1875L3.0625 18L12.0625 9L3.0625 0Z"
                                    fill="#161534"
                                />
                            </svg>
                        </div>
                        <div className="swiper-button-prev trust-prev">
                            <svg
                                width={13}
                                height={18}
                                viewBox="0 0 13 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.9375 0L12.75 2.8125L6.5625 9L12.75 15.1875L9.9375 18L0.9375 9L9.9375 0Z"
                                    fill="#161534"
                                />
                            </svg>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default HomeHaveTrusted
