import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/configuration_keys.json';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AdminLoader from '../../components/adminLoader';

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image'],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
    ],
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image',
    'color', 'background'
];

const Index = () => {
    const navigate = useNavigate();
    const REACT_APP_API_URL = config.REACT_APP_API_URL;
    const [dataSections, setDataSections] = useState([
        {
            id: null,
            image: '',
            tittle: '',
            description: '',
            tempImageUrl: '',
            section_name: 'Main'
        },
        {
            id: null,
            section_name: 'Additional Resources',
        }
    ]);
    const [editor1, setEditor1] = useState({
        position: 1,
        title: "",
        ckeditor_content: "",
    });
    const [editor2, setEditor2] = useState({
        position: 2,
        title: "",
        ckeditor_content: "",
    });
    const [editor3, setEditor3] = useState({
        position: 3,
        title: "",
        ckeditor_content: "",
    });
    const [editor4, setEditor4] = useState({
        position: 4,
        title: "",
        ckeditor_content: "",
    });
    const [editor5, setEditor5] = useState({
        position: 5,
        title: "",
        ckeditor_content: "",
    });
    const [editor6, setEditor6] = useState({
        position: 6,
        title: "",
        ckeditor_content: "",
    });
    const [editor7, setEditor7] = useState({
        position: 7,
        title: "",
        ckeditor_content: "",
    });
    const [editor8, setEditor8] = useState({
        position: 8,
        title: "",
        ckeditor_content: "",
    });
    const [editor9, setEditor9] = useState({
        position: 9,
        title: "",
        ckeditor_content: "",
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const fetchDataSections = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${REACT_APP_API_URL}/admin/faq/getFAQSections`);
            if (response.data && response.data.length > 0) {
                const transformedData = transformBackendData(response.data);
                setDataSections(prevState => {
                    // Deep compare to prevent unnecessary re-renders
                    const isDataChanged = JSON.stringify(prevState) !== JSON.stringify(transformedData);
                    return isDataChanged ? transformedData : prevState;
                });
                setResources(transformedData);
            } else {
                // Reset to default structure if no data
                setDataSections([
                    {
                        id: null,
                        image: '',
                        tittle: '',
                        description: '',
                        tempImageUrl: '',
                        section_name: 'Main'
                    },
                    {
                        id: null,
                        section_name: 'Additional Resources',
                    }
                ]);
                // Reset editor states
                setEditor1({
                    position: 1,
                    title: "",
                    ckeditor_content: "",
                });
                setEditor2({
                    position: 2,
                    title: "",
                    ckeditor_content: "",
                });
                setEditor3({
                    position: 3,
                    title: "",
                    ckeditor_content: "",
                });
                setEditor4({ position: 4, title: "", ckeditor_content: "" });
                setEditor5({ position: 5, title: "", ckeditor_content: "" });
                setEditor6({ position: 6, title: "", ckeditor_content: "" });
                setEditor7({ position: 7, title: "", ckeditor_content: "" });
                setEditor8({ position: 8, title: "", ckeditor_content: "" });
                setEditor9({ position: 9, title: "", ckeditor_content: "" });
            }
        } catch (error) {
            console.error('Error fetching FAQ sections:', error);
        } finally {
            setIsLoading(false);
        }
    }, [REACT_APP_API_URL]);

    const setResources = (data) => {
        const resources = data[1]?.resources || [];
        resources.forEach(resource => {
            const { position, title, ckeditor_content } = resource;
            switch (position) {
                case 1:
                    setEditor1({ position, title, ckeditor_content });
                    break;
                case 2:
                    setEditor2({ position, title, ckeditor_content });
                    break;
                case 3:
                    setEditor3({ position, title, ckeditor_content });
                    break;
                case 4:
                    setEditor4({ position, title, ckeditor_content });
                    break;
                case 5:
                    setEditor5({ position, title, ckeditor_content });
                    break;
                case 6:
                    setEditor6({ position, title, ckeditor_content });
                    break;
                case 7:
                    setEditor7({ position, title, ckeditor_content });
                    break;
                case 8:
                    setEditor8({ position, title, ckeditor_content });
                    break;
                case 9:
                    setEditor9({ position, title, ckeditor_content });
                    break;
                default:
                    break;
            }
        });
    };

    useEffect(() => {
        const isAuthenticated = localStorage.getItem('user');
        if (!isAuthenticated) {
            navigate('/login');
        } else {
            fetchDataSections();
        }
    }, [navigate, fetchDataSections]);

    const transformBackendData = (data) => {
        const mainSection = data.find(section => section.section_name === 'Main') || {};
        const resourcesSection = data.find(section => section.section_name === 'Additional Resources') || {};

        let parsedResources = [];
        try {
            parsedResources = JSON.parse(resourcesSection.resources || '[]');
        } catch (error) {
            console.error('Error parsing resources:', error);
        }

        const transformedResources = Array(9).fill(null).map((_, index) => {
            const resource = parsedResources.find(r => r.position - 1 === index);

            return {
                position: index + 1,
                title: resource?.title || '',
                ckeditor_content: resource?.ckeditor_content || '',
            };
        });

        return [
            {
                id: mainSection.id,
                image: mainSection.image || '',
                tittle: mainSection.tittle || '',
                description: mainSection.description || '',
                section_name: 'Main'
            },
            {
                id: resourcesSection.id,
                section_name: 'Additional Resources',
                resources: transformedResources,
            }
        ];
    };

    const handleInputChange = (sectionIndex, field, value, resourceIndex) => {
        const updatedSections = dataSections.map((section, i) => {
            if (section.section_name === 'Main') {
                return {
                    ...section,
                    [field]: value
                };
            }
            return section;
        });
        setDataSections(updatedSections);
    };

    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            // Create a temporary URL for preview
            const tempUrl = URL.createObjectURL(file);
            const updatedSections = dataSections.map((section, i) => {
                if (i === index) {
                    return {
                        ...section,
                        image: file,
                        tempImageUrl: tempUrl
                    };
                }
                return section;
            });
            setDataSections(updatedSections);
        }
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        const formData = new FormData();
        const sectionsData = dataSections.map(section => {
            if (section.section_name === 'Main') {
                return {
                    id: section.id,
                    tittle: section.tittle,
                    description: section.description,
                    sectionName: section.section_name,
                    image: typeof section.image === 'string' ? section.image : null
                };
            } else if (section.section_name === 'Additional Resources') {
                return {
                    sectionName: section.section_name,
                    id: section.id,
                    resources: [
                        { position: editor1.position, title: editor1.title, content: editor1.content, ckeditor_content: editor1.ckeditor_content },
                        { position: editor2.position, title: editor2.title, content: editor2.content, ckeditor_content: editor2.ckeditor_content },
                        { position: editor3.position, title: editor3.title, content: editor3.content, ckeditor_content: editor3.ckeditor_content },
                        { position: editor4.position, title: editor4.title, content: editor4.content, ckeditor_content: editor4.ckeditor_content },
                        { position: editor5.position, title: editor5.title, content: editor5.content, ckeditor_content: editor5.ckeditor_content },
                        { position: editor6.position, title: editor6.title, content: editor6.content, ckeditor_content: editor6.ckeditor_content },
                        { position: editor7.position, title: editor7.title, content: editor7.content, ckeditor_content: editor7.ckeditor_content },
                        { position: editor8.position, title: editor8.title, content: editor8.content, ckeditor_content: editor8.ckeditor_content },
                        { position: editor9.position, title: editor9.title, content: editor9.content, ckeditor_content: editor9.ckeditor_content }
                    ]
                };
            }
            return section;
        });

        formData.append('sections', JSON.stringify(sectionsData));

        // Append only the new files
        dataSections?.forEach((section, index) => {
            if (section.image instanceof File) {
                formData.append(`sections[${index}][image]`, section.image);
            }
        });

        try {
            const url = `${REACT_APP_API_URL}/admin/faq/addOrUpdateFAQ`;
            let data = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setSuccessMessage(data.data);
            setTimeout(() => setSuccessMessage(''), 5000);
            fetchDataSections();
        } catch (error) {
            console.error('Error saving Product:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/login');
    };

    const handleBack = () => {
        navigate(-1);
    };

    const allEditors = [editor1, editor2, editor3, editor4, editor5, editor6, editor7, editor8, editor9];

    const isPositionUnique = (position, editorIndex) => {
        return !allEditors.some((editor, index) => index !== editorIndex && editor.position === position);
    };

    const handleChange = (e, editorIndex) => {
        const { name, value } = e.target;

        // If the position field is changed, check if it is unique
        if (name === "position") {
            const newPosition = Number(value);  // Ensure the value is a number
            if (!isPositionUnique(newPosition, editorIndex)) {
                alert("Position must be unique. Please enter a different position.");
                return;
            }
        }

        if (editorIndex === 0) {
            setEditor1({ ...editor1, [name]: value });
        } else if (editorIndex === 1) {
            setEditor2({ ...editor2, [name]: value });
        } else if (editorIndex === 2) {
            setEditor3({ ...editor3, [name]: value });
        } else if (editorIndex === 3) {
            setEditor4({ ...editor4, [name]: value });
        } else if (editorIndex === 4) {
            setEditor5({ ...editor5, [name]: value });
        } else if (editorIndex === 5) {
            setEditor6({ ...editor6, [name]: value });
        } else if (editorIndex === 6) {
            setEditor7({ ...editor7, [name]: value });
        } else if (editorIndex === 7) {
            setEditor8({ ...editor8, [name]: value });
        } else if (editorIndex === 8) {
            setEditor9({ ...editor9, [name]: value });
        }
    };

    const handleQuillChange = (value, editorIndex) => {
        if (editorIndex === 0) {
            setEditor1({ ...editor1, ckeditor_content: value });
        } else if (editorIndex === 1) {
            setEditor2({ ...editor2, ckeditor_content: value });
        } else if (editorIndex === 2) {
            setEditor3({ ...editor3, ckeditor_content: value });
        } else if (editorIndex === 3) {
            setEditor4({ ...editor4, ckeditor_content: value });
        } else if (editorIndex === 4) {
            setEditor5({ ...editor5, ckeditor_content: value });
        } else if (editorIndex === 5) {
            setEditor6({ ...editor6, ckeditor_content: value });
        } else if (editorIndex === 6) {
            setEditor7({ ...editor7, ckeditor_content: value });
        } else if (editorIndex === 7) {
            setEditor8({ ...editor8, ckeditor_content: value });
        } else if (editorIndex === 8) {
            setEditor9({ ...editor9, ckeditor_content: value });
        }
    };

    return (
        <div style={styles.mainContainer}>
            <div style={styles.headerWrapper}>
                <div style={styles.header}>
                    <div style={styles.breadcrumbs}>
                        <Link to="/dashboard" style={styles.breadcrumbLink}>Dashboard</Link>
                        <span style={styles.breadcrumbSeparator}>/</span>
                        <span style={styles.currentPage}>FAQ Page</span>
                    </div>
                    <div style={styles.headerRight}>
                        <button style={styles.logoutButton} onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                </div>
            </div>
            <div style={styles.container}>
                <h1 style={styles.title}>FAQ Page</h1>
                {isLoading ? (
                    <AdminLoader />
                ) : (
                    <>
                        <form onSubmit={handleSubmit} style={styles.form}>
                            {dataSections.map((section, index) => (
                                <div key={index} style={styles.section}>
                                    <h2 style={styles.sectionTitle}>{section.section_name} Section</h2>
                                    {section.section_name === 'Main' && section.tempImageUrl ? (
                                        <img
                                            src={section.tempImageUrl}
                                            alt={`Banner ${index + 1}`}
                                            style={styles.image}
                                        />
                                    ) : section.image && (
                                        <img
                                            src={`${REACT_APP_API_URL}/uploads/${section.image}`}
                                            alt={`Banner ${index + 1}`}
                                            style={styles.image}
                                        />
                                    )}

                                    {section.section_name === 'Main' && (
                                        <>
                                            <label style={styles.label}>Main Banner Image</label>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleFileChange(index, e)}
                                                style={styles.input}
                                                required={!section.image}
                                            />
                                            <label style={styles.label}>Title</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Title"
                                                value={section.tittle}
                                                onChange={(e) => handleInputChange(index, 'tittle', e.target.value)}
                                                style={styles.input}
                                                required
                                            />
                                            <label style={styles.label}>Description</label>
                                            <textarea
                                                placeholder="Enter Description"
                                                value={section.description}
                                                onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                                style={styles.textarea}
                                                required
                                            />
                                        </>
                                    )}

                                    {section.section_name === 'Additional Resources' && (
                                        <div>
                                            <div>
                                                <label style={styles.label}>Position</label>
                                                <input
                                                    type="number"
                                                    onWheel={(e) => {
                                                        e.preventDefault();
                                                        (e.target).blur();
                                                    }}
                                                    name="position"
                                                    placeholder="Enter Position (1-9)"
                                                    value={editor1.position}
                                                    onChange={(e) => handleChange(e, 0)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Resource Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter Resource Title"
                                                    value={editor1.title}
                                                    onChange={(e) => handleChange(e, 0)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Detailed Resource Information</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={editor1.ckeditor_content}
                                                    onChange={(value) => handleQuillChange(value, 0)}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                            </div>

                                            <div>
                                                <label style={styles.label}>Position</label>
                                                <input
                                                    type="number"
                                                    onWheel={(e) => {
                                                        e.preventDefault();
                                                        (e.target).blur();
                                                    }}
                                                    name="position"
                                                    placeholder="Enter Position (1-9)"
                                                    value={editor2.position}
                                                    onChange={(e) => handleChange(e, 1)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Resource Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter Resource Title"
                                                    value={editor2.title}
                                                    onChange={(e) => handleChange(e, 1)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Detailed Resource Information</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={editor2.ckeditor_content}
                                                    onChange={(value) => handleQuillChange(value, 1)}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                            </div>

                                            <div>
                                                <label style={styles.label}>Position</label>
                                                <input
                                                    type="number"
                                                    onWheel={(e) => {
                                                        e.preventDefault();
                                                        (e.target).blur();
                                                    }}
                                                    name="position"
                                                    placeholder="Enter Position (1-9)"
                                                    value={editor3.position}
                                                    onChange={(e) => handleChange(e, 2)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Resource Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter Resource Title"
                                                    value={editor3.title}
                                                    onChange={(e) => handleChange(e, 2)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Detailed Resource Information</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={editor3.ckeditor_content}
                                                    onChange={(value) => handleQuillChange(value, 2)}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                            </div>

                                            <div>
                                                <label style={styles.label}>Position</label>
                                                <input
                                                    type="number"
                                                    onWheel={(e) => {
                                                        e.preventDefault();
                                                        (e.target).blur();
                                                    }}
                                                    name="position"
                                                    placeholder="Enter Position (1-9)"
                                                    value={editor4.position}
                                                    onChange={(e) => handleChange(e, 3)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Resource Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter Resource Title"
                                                    value={editor4.title}
                                                    onChange={(e) => handleChange(e, 3)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Detailed Resource Information</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={editor4.ckeditor_content}
                                                    onChange={(value) => handleQuillChange(value, 3)}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                            </div>

                                            <div>
                                                <label style={styles.label}>Position</label>
                                                <input
                                                    type="number"
                                                    onWheel={(e) => {
                                                        e.preventDefault();
                                                        (e.target).blur();
                                                    }}
                                                    name="position"
                                                    placeholder="Enter Position (1-9)"
                                                    value={editor5.position}
                                                    onChange={(e) => handleChange(e, 4)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Resource Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter Resource Title"
                                                    value={editor5.title}
                                                    onChange={(e) => handleChange(e, 4)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Detailed Resource Information</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={editor5.ckeditor_content}
                                                    onChange={(value) => handleQuillChange(value, 4)}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                            </div>

                                            <div>
                                                <label style={styles.label}>Position</label>
                                                <input
                                                    type="number"
                                                    onWheel={(e) => {
                                                        e.preventDefault();
                                                        (e.target).blur();
                                                    }}
                                                    name="position"
                                                    placeholder="Enter Position (1-9)"
                                                    value={editor6.position}
                                                    onChange={(e) => handleChange(e, 5)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Resource Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter Resource Title"
                                                    value={editor6.title}
                                                    onChange={(e) => handleChange(e, 5)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Detailed Resource Information</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={editor6.ckeditor_content}
                                                    onChange={(value) => handleQuillChange(value, 5)}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                            </div>

                                            <div>
                                                <label style={styles.label}>Position</label>
                                                <input
                                                    type="number"
                                                    onWheel={(e) => {
                                                        e.preventDefault();
                                                        (e.target).blur();
                                                    }}
                                                    name="position"
                                                    placeholder="Enter Position (1-9)"
                                                    value={editor7.position}
                                                    onChange={(e) => handleChange(e, 6)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Resource Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter Resource Title"
                                                    value={editor7.title}
                                                    onChange={(e) => handleChange(e, 6)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Detailed Resource Information</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={editor7.ckeditor_content}
                                                    onChange={(value) => handleQuillChange(value, 6)}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                            </div>

                                            <div>
                                                <label style={styles.label}>Position</label>
                                                <input
                                                    type="number"
                                                    onWheel={(e) => {
                                                        e.preventDefault();
                                                        (e.target).blur();
                                                    }}
                                                    name="position"
                                                    placeholder="Enter Position (1-9)"
                                                    value={editor8.position}
                                                    onChange={(e) => handleChange(e, 7)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Resource Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter Resource Title"
                                                    value={editor8.title}
                                                    onChange={(e) => handleChange(e, 7)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Detailed Resource Information</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={editor8.ckeditor_content}
                                                    onChange={(value) => handleQuillChange(value, 7)}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                            </div>

                                            <div>
                                                <label style={styles.label}>Position</label>
                                                <input
                                                    type="number"
                                                    onWheel={(e) => {
                                                        e.preventDefault();
                                                        (e.target).blur();
                                                    }}
                                                    name="position"
                                                    placeholder="Enter Position (1-9)"
                                                    value={editor9.position}
                                                    onChange={(e) => handleChange(e, 8)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Resource Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter Resource Title"
                                                    value={editor9.title}
                                                    onChange={(e) => handleChange(e, 8)}
                                                    style={styles.input}
                                                    required
                                                />
                                                <label style={styles.label}>Detailed Resource Information</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={editor9.ckeditor_content}
                                                    onChange={(value) => handleQuillChange(value, 8)}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                            </div>
                                        </div>
                                    )}

                                </div>
                            ))}
                            {successMessage && <div style={styles.successMessage}>{successMessage}</div>}

                            <div style={styles.bottomActions}>
                                <button onClick={handleBack} style={styles.backButton}>
                                    ← Back
                                </button>
                                <div style={styles.rightButtons}>
                                    <button
                                        type="submit"
                                        style={styles.submitButton}
                                    >
                                        Save FAQ Sections
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

const styles = {
    editorContainer: {
        marginBottom: '20px',
        minHeight: '200px'
    },
    editor: {
        height: '200px',
        marginBottom: '50px' // Add extra margin to account for Quill toolbar
    },
    mainContainer: {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
    },
    headerWrapper: {
        width: '100%',
        backgroundColor: '#fff',
        borderBottom: '1px solid #e0e0e0',
        marginBottom: '10px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '15px 20px',
    },
    breadcrumbs: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    breadcrumbLink: {
        color: '#666',
        textDecoration: 'none',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    breadcrumbSeparator: {
        color: '#999',
        fontSize: '14px',
    },
    currentPage: {
        color: '#333',
        fontWeight: '500',
        fontSize: '14px',
    },
    headerRight: {
        display: 'flex',
        gap: '10px',
    },
    image: {
        maxWidth: '100px',
        height: 'auto',
        borderRadius: '4px',
    },
    bottomActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        paddingTop: '20px',
        borderTop: '1px solid #e0e0e0',
    },
    rightButtons: {
        display: 'flex',
        gap: '10px',
    },
    backButton: {
        padding: '10px 20px',
        backgroundColor: '#f0f0f0',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    submitButton: {
        backgroundColor: '#2196F3',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#1976D2',
        },
    },
    logoutButton: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    container: {
        // padding: '20px',
        backgroundColor: '#f8f9fa',
        minHeight: '100vh',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#343a40',
    },
    successMessage: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#d4edda',
        color: '#155724',
        border: '1px solid #c3e6cb',
        borderRadius: '4px',
        textAlign: 'center',
    },
    form: {
        padding: '6px',
        maxWidth: '600px',
        margin: '0 auto',
    },
    section: {
        backgroundColor: '#fff',
        padding: '20px',
        margin: '10px 0',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    sectionTitle: {
        textAlign: 'center',
        color: '#495057',
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
        marginTop: '10px'
    },
    input: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
    },
    textarea: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '16px',
        resize: 'vertical',
    },
};

export default Index;