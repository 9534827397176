import axios from 'axios';
import config from '../config/configuration_keys.json';

const REACT_APP_API_URL = config.REACT_APP_API_URL;

// Define Axios instance with a baseURL
const apiClient = axios.create({
  baseURL: REACT_APP_API_URL, // The prefix URL
});


export default apiClient